<template>
  <div class="home_box">
    <!-- email -->
    <!-- <Top name="仙境传奇-冰雪无限刀"></Top> -->
     <van-sticky>
            <div class="public_header">
                <span>{{total_game_name}}</span>
            </div>
            <span class="switchover_game"  v-if="gameIsShow" @click="isSelectGameShow=true">切换游戏<van-icon name="exchange" style="vertical-align: middle;" /></span>
            <van-popup v-model="isSelectGameShow" position="right" :style="{ height: '100%' ,width:'100%'}">
              <div class="select_game_box">
                <van-icon name="arrow-left" v-if="isSelectGameBack" class="select_game_back" @click="isSelectGameShow=false"/>
                <span>请选择游戏</span>
              </div>
              <div class="game_item_box">
                <ul class="more">
                        <li v-for="item in gameLinkList" :key="item.id">
                            <img @click="getGameId(item)" :src="item.src"/><span>{{ item.name }}</span>
                        </li>
                      </ul>
              </div>
              <div class="official_box">
                <p style="border-bottom: 1px #e1dede solid;height:30px">官方网站</p>
                <ul class="moreGame">
                    <van-skeleton title :row="3" style="padding: 0;" v-if="gameLinkList.length==0"/>
                    <li v-for="(item, index) in gameLinkList" :key="index">
                        <a :href="item.url" target="_blank" style="display: block;">
                            <div class="moreGame_bg">
                                <img :src="item.src" alt="">
                            </div>
                            <div class="moreGame_name"><span>{{item.name}}</span></div>
                        </a>
                    </li>
                    <van-empty description="暂无数据" style="padding: 0;" v-if="gameLinkList.length==0"/>
                </ul>
                <ul class="moreGame">
                    <li>
                        <a :href="newLink" target="_blank" style="display: block;">
                            <div class="moreGame_bg">
                                <img src="../../assets/images/more_heard8.png" alt="">
                            </div>
                            <div class="moreGame_name" style="height: 4.71875rem;width:auto"><span>新盒子（新老数据余额以及货币不互通）</span></div>
                        </a>
                    </li>
                    <li></li>
                    <li></li>
                </ul>
              </div>
            </van-popup>
      </van-sticky>
      <div class="goods_type_box">
        <ul>
          <li v-for="(item) in goods_type_list" :key="item.filter_type">
            <div :class="{goods_type_bg:filter_type_isActive === item.filter_type}" @click="goodsTypeClick(item.filter_type)"><span>{{item.filter_name}}</span></div>
          </li>
        </ul>
      </div>
      <!-- 仙玉组合支付输入框 -->
      <!-- v-show="isAddPayRecommend" -->
      <!-- 货币 -->
    <div class="currency_box" v-show="isAddPayRecommend==true">
      <div class="index_input_box" :class="{index_input_box_height:formDate.channel_id==0 && gameIsShow}">
         <van-form>
          <van-field
            readonly
            clickable
            name="picker"
            :value="currencyForm.edition"
            label="游戏版本"
            placeholder="点击选择版本"
            @click="changeClick1(0)"
            v-if="formDate.channel_id==0 && gameIsShow"
          />
          <van-popup v-model="currencyForm.editionPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="currencyForm.editionData"
              @confirm="onConfirm1"
              @cancel="currencyForm.editionPicker = false"
            />
          </van-popup>

            <van-field
              readonly
              clickable
              name="picker"
              :value="currencyForm.region"
              label="收货大区"
              placeholder="请选择收货大区"
              @click="changeClick1(1)"
            />
            <van-popup
              v-model="currencyForm.gamePicker"
              position="bottom"
              duration="0.15"
              get-container="body"
            >
              <van-picker
                show-toolbar
                value-key="regione_name"
                :columns="currencyForm.regions"
                @confirm="onConfirm1"
                @cancel="currencyForm.gamePicker = false"
              />
            </van-popup>
            <van-field
              readonly
              clickable
              name="picker"
              :value="currencyForm.server"
              label="收货区服"
              placeholder="请选择收货区服"
              @click="changeClick1(2)"
            />
            <van-popup
              v-model="currencyForm.serverPicker"
              position="bottom"
              duration="0.15"
              get-container="body"
            >
              <van-picker
                show-toolbar
                value-key="server_name"
                :columns="currencyForm.servers"
                @confirm="onConfirm1"
                @cancel="currencyForm.serverPicker = false"
              />
            </van-popup>
            <van-field
              readonly
              clickable
              name="picker"
              :value="currencyForm.role"
              label="收货角色"
              placeholder="请选择收货角色"
              @click="changeClick1(3)"
            />
            <van-popup
              v-model="currencyForm.rolePicker"
              position="bottom"
              duration="0.15"
              get-container="body"
            >
              <van-picker
                show-toolbar
                value-key="chrname"
                :columns="currencyForm.roles"
                @confirm="onConfirm1"
                @cancel="currencyForm.rolePicker = false"
              />
            </van-popup>
            <span class="role_span" @click.stop="transactionRoleHandle"><van-icon name="question" />交易规则</span>
        </van-form>
      </div>
      <!-- <div v-show="isShowServeStatus"> -->
        <!-- <p class="xian_yu_box_p">区服状态：{{serve_status}}</p> -->
        <!-- <p class="xian_yu_box_p" style="padding-bottom:0.2rem">可购买范围：{{regioneStr}}<span @click="show_pay_scope=true">查看更多</span></p> -->
        <!-- <van-collapse v-model="activeNames">
          <van-collapse-item title="标题1" name="1">内容</van-collapse-item>
        </van-collapse> -->
      <!-- </div> -->
      <!-- 点击查看购买范围 -->
      <van-dialog v-model="show_pay_scope" title="可购买范围" class="show_pay_scope_css" confirmButtonText='知道了' closeOnClickOverlay>
      <div class="show_pay_scope" v-html="can_pay_scope">
      </div>
     </van-dialog>
      <!-- 未选择收货角色提醒 -->
      <div class="warn_box_null" v-show="closeNullWarn==true">
        <img src="../../assets/images/aaaaaa@3x.png" alt="">
        <span>请先选择收货角色</span>
      </div>
      <div class="xian_yu_box" v-show="closeNullWarn==false">
        <ul class="xian_yu_item_box">
          <li v-for="item in scale_goods" :key="item.id" @click="clickXianYuItem(item)">
            <img src="../../assets/images/box_update/xian_yu_total@2x.png" alt="" v-if="filter_type_isActive=='2' && (formDate.channel_id==18||formDate.channel_id==33)">
            <img src="../../assets/images/box_update/94.png" alt="" v-if="filter_type_isActive=='4'">
            <img src="../../assets/images/box_update/94.png" alt="" v-if="formDate.channel_id!=18 && formDate.channel_id!=33">
            <div class="xian_yu_item">
              <h1 style="font-size:0.85rem;line-height:24px">1:{{item.rebate}}</h1>
              <h1 style="font-size:0.85rem">当前比例挂单数量：{{item.coin_num}}</h1>
            </div>
          </li>
        </ul>
      </div>
  </div>
  <!-- 仙玉交易规则 -->
  <van-dialog v-model="showTransactionRole" title="交易规则" class="show_transaction_role_css" confirmButtonText='知道了' closeOnClickOverlay>
      <div class="show_transaction_role_box" v-if="tradingRules==''">
        <div v-if="formDate.channel_id==18">
          <p>所有区服互通</p>
          <p style="color:#ff4e00"> 交易税率：15%</p>
        </div>
        <div v-else-if="formDate.channel_id==33">
          <p>开服时间1-7天内的区服，仙玉互通。</p>
          <p>开服时间8-29天内的区服，仙玉互通。</p>
          <p>开服时间大于等于30天的区服，仙玉互通。</p>
          <p>开服当日为第一天，计算一日。</p>
          <p>元宝只在本服互通</p>
          <p>注：开服时间以主区为准。</p>
          <p style="color:#ff4e00"> 交易税率：15%</p>
        </div>
        <div v-else-if="formDate.channel_id==9">
          <p> 开服时间小于100天大区，所有大区跨服。</p>
          <p> 开服时间100天-500天（含500天）所有大区跨服。</p>
          <p> 500天以上所有大区跨服。</p>
          <p>开服当日为第一天，计算一日。</p>
          <p> 注：开服时间以主区为准。</p>
          <p style="color:#ff4e00"> 交易税率：15%</p>
        </div>
        <div v-else>
          <p> 开服超过200天的区服，互通元宝交易。</p>
          <p> 开服未超过200天的区服，互通元宝交易。</p>
          <p> 开服当日为第一天，计算一日。</p>
          <p> 注：开服时间以主区为准。</p>
          <p style="color:#ff4e00"> 交易税率：15%</p>
        </div>
        <!-- <p>仙玉交易根据区服开区时间进行互通</p>
        <p>1.开区时间{{transactionRoleData[0].start_day_num}}~{{transactionRoleData[0].end_day_num}}天期间，仙玉互通;</p>
        <p>2.开区时间{{transactionRoleData[1].start_day_num}}~{{transactionRoleData[1].end_day_num}}天期间，仙玉互通;</p>
        <p>3.开区时间{{transactionRoleData[2].start_day_num}}~{{transactionRoleData[2].end_day_num}}天期间，仙玉互通;</p>
        <p>4.开区时间≥{{transactionRoleData[3].start_day_num}}天，仙玉互通。</p> -->
      </div>
      <div class="show_transaction_role_box" v-else>
        <div>
          <p v-html="tradingRules"></p>
          <p style="color:#ff4e00"> 交易税率：15%</p>
        </div>
      </div>
  </van-dialog>
  <!-- 选择仙玉/元宝输入数量 -->
  <div class="dialogInput">
      <van-dialog
          v-model="isSelfNum"
          :title="'当前选中'+currencyName+'交易比例 1：' + xian_yu_scale"
          show-cancel-button
          confirmButtonText='确认支付'
          :confirmButtonColor="xian_yu_num == '' ||xian_yu_num == 0 || xian_yu_num>xian_yu_item_num? '#000' : '#ff4e00'"
          @confirm="surePayNumHandel"
          :before-close="onBeforeClose"
      >
      <!-- oninput="if(this.xian_yu_num.length==1){this.xian_yu_num=this.xian_yu_num.replace(/[^1-9]/g,'')}else{this.xian_yu_num=this.xian_yu_num.replace(/\D/g,'')}" -->
        <div class="payRecommendBox">
          <van-field v-model="xian_yu_num"
              type="digit"
              label="我要购买"
              class="payRecommendBox1"
              @input="saleInput"/>
              <span style="position: absolute;top: 63px;right: 85px;">万</span>
          <div class="payRecommendBox2">
            <p>需支付金额:￥ <span>{{need_pay_num}}</span></p>
          </div>
        </div>
        <!-- <p style="font-size:0.8rem;color:#e02d43;text-align:left;padding-left:3rem;">*单笔购买仙玉数额最大{{ numTop }}万</p> -->
      </van-dialog>
    </div>
    <!-- 点击确认金额去购买弹窗 -->
    <div class="dialogGoPay">
      <van-dialog
          v-model="isEndGoPay"
          closeOnClickOverlay
          show-cancel-button
          @confirm="goPayHandel"
          @cancel="cancelGoPayHandel"
          cancelButtonText="不要了"
          :confirmButtonText="'去支付' + ' (' + downTime + 's' + ')'"
          :confirmButtonColor="OrderRouterList.code == 1 ? '#000' : '#ff4e00'"
      >
        <div class="go_pay_content">
          <span class="go_pay_span_color">恭喜你！</span><br/>
          <span class="go_pay_span_color" style=" margin-bottom: .4167rem;"
          >想要的{{currencyName}}全部拿下，是否去支付？</span
          >
          <div class="go_pay_content_num">
            <p>
              预计购买{{currencyName}}：<span class="go_pay_span_color"
            >{{ xian_yu_num }}万</span
            >
            </p>
            <p>
              实际拿到{{currencyName}}：<span class="go_pay_span_color"
            >{{ OrderRouterList.item_num }}万</span
            >
            </p>
            <p>
              应付总额：<span class="go_pay_span_color"
            >￥{{ OrderRouterList.money }}</span
            >
            </p>
          </div>
          <!-- <span class="bad_scalp" v-if="orderSwitch==1">
            为避免恶意刷单，组合支付仅限制10个订单数
          </span> -->
          <span class="bad_scalp">当前的{{currencyName}}数可能被其他玩家抢单，您可以购买当前抢到的{{currencyName}}数或稍后再进行购买</span>
        </div>
      </van-dialog>
    </div>

      <!-- 筛选 -->
    <van-row
        v-show="isAddPayRecommend==false"
        class="filterColumn"
        style="border-bottom:1px solid #bbbbbb;font-size: .875rem;"
    >
      <van-col
          span="6"
          @click="sortMeans(1)"
          :class="{ action: isActive == 1 }"
      >默认排序
      </van-col
      >
      <van-col span="6" @click="sortMeans(2)" :class="{ action: isActive == 2 }"
      >公示期
      </van-col
      >
      <van-col
          class="sort"
          @click="sortMeans(3)"
          :class="{ action_top: isActive == 3, action_bottom: isActive == 4 }"
          span="6"
      >
        <span>{{ formDate.game == "无限刀" ? "总价" : "价格" }}</span>
        <van-icon class="top" name="arrow-up"/>
        <van-icon class="bottom" name="arrow-down"/>
      </van-col>
      <van-col span="6" @click="screenClick">
        <span style="vertical-align: middle;">筛选</span>
        <van-icon style="vertical-align: middle;" name="filter-o"/>
      </van-col>
    </van-row>
    <van-pull-refresh v-show="isAddPayRecommend==false" v-model="refreshing" @refresh="onRefresh">
      <van-list
          style="padding-bottom: 50px;"
          class="list_side"
          v-model="loading"
          :finished="finished"
          :finished-text="noMore"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          :immediate-check="loadcheck"
          @load="onLoad"
      >
        <van-cell v-for="item in list" :key="item.id">
          <div class="index_list" @click="characterdetails(item.goods_id)">
            <span class="index_list_l">
              <img class="profilePhoto" :src="item.imgSrc"/>
              <!-- <van-image class="profilePhoto" :src="item.imgSrc" /> -->
              <span class="index_list_c">
                <div class="index_list_c_t" v-if="item.onsale_type == 1">
                  {{ item.channel_name }}\{{ item.job }}\{{ item.lv }}级
                </div>
                <div class="index_list_c_t" v-else-if="item.onsale_type == 2">
                  {{ item.channel_name }}\{{ item.item_name }}&ensp;{{
                    item.item_num
                  }}万
                </div>
                <div class="index_list_c_t" v-else-if="item.onsale_type == 3">
                  {{ item.channel_name }}\{{ item.item_name }}
                </div>
                <div class="index_list_c_b">
                  <span
                      class="index_list_c_b_t"
                      :class="{
                      xiajia: item.status == 7,
                      shanchu: item.status == 4 || item.status == 5,
                      yishouchu: item.status == 3,
                    }"
                  >{{ item.status_name }}</span
                  >
                  <span class="index_list_c_b_b">{{ item.surplus_time }}</span>
                </div>
              </span>
            </span>
            <span class="index_list_r">
              <div class="index_list_r_t">{{ item.server_name }}</div>
              <div class="index_list_r_b">￥{{ item.price }}</div>
              <div
                  style="color:#909090"
                  v-if="formDate.game == '无限刀' && item.onsale_type == 2"
              >
                1元≈{{ item.currency_proportion }}仙玉
              </div>
            </span>
          </div>
          <!-- </router-link> -->
        </van-cell>
      </van-list>
    </van-pull-refresh>
    <van-overlay :show="dialogShow" class="notice">
      <div class="wrap">
        <div class="block_w">
          <h3>公告</h3>
          <van-icon name="cross" size="20" @click="noticeClose"/>
          <div class="wrap_c" v-html="noticeHtml" v-if="noticeType == 1"></div>
          <div class="wrap_c" v-else>
            <img :src="noticeSrc" alt="" style="width: 100%;"/>
          </div>
          <div class="login_btns wrap_b">
            <van-checkbox v-model="dialogChecked">不再提示</van-checkbox>
            <van-button
                round
                type="info"
                size="large"
                color="linear-gradient(to right, rgb(255, 112, 77), rgb(247, 55, 39))"
                @click="confirmClick"
            >确认
            </van-button
            >
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 筛选选择项渲染 -->
    <van-popup
        class="popup_top"
        v-model="show"
        round
        position="top"
        :style="{ padding: '0.6rem 1.09375rem 0.3rem', height: '70%' }"
        :close-on-click-overlay="clickoverlay"
        @click-overlay="overlayClick"
    >
     <!-- @close="downFilterHandle" -->
      <!-- <van-field
        style="background: #efefef; margin-bottom: 0.90625rem;"
        readonly
        clickable
        name="picker"
        :value="formDate.game"
        label="游戏："
        placeholder="点击选择游戏"
        @click="formDate.gameShowPicker = true"
      />
      <van-popup
          v-model="formDate.gameShowPicker"
          position="bottom"
          duration="0.15"
          get-container="body"
      >
        <van-picker
            show-toolbar
            value-key="channelName"
            :columns="formDate.games"
            @confirm="onConfirm"
            @cancel="formDate.gameShowPicker = false"
        />
      </van-popup>
      <van-field
          style="background: #efefef;margin-bottom: .90625rem;"
          readonly
          clickable
          name="picker"
          :value="formDate.classify"
          label="分类："
          placeholder="点击选择"
          @click="formDate.classifyShowPicker = true"
      />
      <van-popup
          v-model="formDate.classifyShowPicker"
          position="bottom"
          duration="0.15"
          get-container="body"
      >
        <van-picker
            show-toolbar
            value-key="filter_name"
            :columns="formDate.classifys"
            @confirm="onClassify"
            @cancel="formDate.classifyShowPicker = false"
        />
      </van-popup> -->
      <van-row
          style="margin-bottom: .90625rem;"
          v-for="(item, index) in formlist.input_box"
          :key="index"
          v-if="index % 2 == 0 && formlist.input_box.length !== 0"
      >
        <van-col span="11"
        >
          <van-field
              style="background: #efefef;padding: .5625rem;"
              v-model="boundData[item.fieldName]"
              type="number"
              :placeholder="item.defaultContent"
          />
        </van-col>
        <van-col span="2" style="padding: .5625rem;color:#cccccc">-</van-col>
        <van-col span="11"
        >
          <van-field
              style="background: #efefef;padding: .5625rem;"
              v-model="boundData[formlist.input_box[index + 1].fieldName]"
              type="number"
              :placeholder="formlist.input_box[index + 1].defaultContent"
          />
        </van-col>
      </van-row>
      <van-row
          class="profession progroup"
          style="margin-bottom: .90625rem; padding-right: 0.5rem"
          gutter="15"
          v-if="formlist.multi_select_button_fillet.length !== 0"
      >
        <!-- formDate.professionCheck -->
        <van-checkbox-group
            v-model="boundData[formlist.multi_select_button_fillet[0].fieldName]"
        >
          <van-col
              span="6"
              v-for="item in formlist.multi_select_button_fillet"
              :key="item.id"
          >
            <van-checkbox
                :name="item.defaultValue"
                :class="{
                checked:
                  boundData[
                    formlist.multi_select_button_fillet[0].fieldName
                  ].indexOf(item.defaultValue) > -1,
              }"
            >{{ item.defaultContent }}
            </van-checkbox
            >
          </van-col>
        </van-checkbox-group>
      </van-row>
      <van-row
          v-if="formlist.multi_select_btn_right_angle.length !== 0"
          class="profession progroup classification"
          style="margin-bottom: .90625rem;"
          gutter="15"
      >
        <van-checkbox-group
            v-model="
            boundData[formlist.multi_select_btn_right_angle[0].fieldName]
          "
        >
          <van-col
              span="8"
              v-for="item in formlist.multi_select_btn_right_angle"
              :key="item.id"
          >
            <van-checkbox
                :name="item.defaultValue"
                :class="{
                checked:
                  boundData[
                    formlist.multi_select_btn_right_angle[0].fieldName
                  ].indexOf(item.defaultValue) > -1,
              }"
            >{{ item.defaultContent }}
            </van-checkbox
            >
          </van-col>
        </van-checkbox-group>
      </van-row>

      <van-row class="profession" style="margin-bottom: 0.90625rem" gutter="15">
        <van-col span="12">
          <van-field
              style="background: #efefef;margin-bottom: .90625rem;"
              readonly
              name="picker"
              :value="formDate.region"
              label="大区："
              placeholder="请选择"
              @click="changeClick(1)"
          />
          <van-popup
              v-model="formDate.regionShowPicker"
              position="bottom"
              duration="0.15"
              get-container="body"
          >
            <van-picker
                show-toolbar
                value-key="regione_name"
                :columns="formDate.regions"
                @confirm="onConfirm"
                @cancel="formDate.regionShowPicker = false"
            />
          </van-popup>
        </van-col>
        <van-col span="12">
          <van-field
              style="background: #efefef;margin-bottom: .90625rem;"
              readonly
              name="picker"
              :value="formDate.server"
              label="服务器："
              placeholder="请选择"
              @click="changeClick(2)"
          />
          <van-popup
              v-model="formDate.serverShowPicker"
              position="bottom"
              duration="0.15"
              get-container="body"
          >
            <van-picker
                show-toolbar
                value-key="server_name"
                :columns="formDate.servers"
                @confirm="onConfirm"
                @cancel="formDate.serverShowPicker = false"
            />
          </van-popup>
        </van-col>
        <van-row
            style="margin-bottom: .90625rem;"
            v-for="(item, index) in formlist.input_box_title"
            :key="index"
            v-if="index % 2 == 0  && formlist.input_box_title.length !== 0"
        >
          <van-col span="5" style="padding: .5625rem;color:#323233"
          >{{ item.defaultContent }}≥：</van-col >
          <van-col span="7"
          >
            <van-field
                style="background: #efefef;padding: .5625rem;"
                v-model="boundData[item.fieldName]"
                type="number"
                placeholder=""
            />
          </van-col>
          <van-col span="5" style="padding: .5625rem;color:#323233"
          >{{
              formlist.input_box_title[index + 1].defaultContent
            }}≥：
          </van-col
          >
          <van-col span="7"
          >
            <van-field
                style="background: #efefef;padding: .5625rem;"
                v-model="boundData[formlist.input_box_title[index + 1].fieldName]"
                type="number"
                placeholder=""
            />
          </van-col>
        </van-row>
        <van-row
            style="margin-bottom: .90625rem;"
            v-if="formlist.check_box.length !== 0"
        >
          <van-col span="4" style="padding: .5625rem;color:#323233"
          >黑货：
          </van-col
          >
          <van-col span="20">
            <van-checkbox-group
                v-model="boundData[formlist.check_box[0].fieldName]"
                direction="horizontal"
            >
              <van-col
                  span="8"
                  v-for="(item, index) in formlist.check_box"
                  :key="index"
              >
                <van-checkbox
                    style="padding: .6925rem 0;"
                    :name="item.defaultValue"
                    shape="round"
                >{{ item.defaultContent }}
                </van-checkbox
                >
              </van-col
              >
            </van-checkbox-group>
          </van-col>
          <div class="annotation">*注：勾选后其它筛选无效仅搜索黑货</div>
        </van-row>
        <van-row
            style="margin-bottom: .90625rem;"
            v-for="(item, index) in formlist.long_input_box"
            :key="index"
        >
          <van-field
              style="background: #efefef;padding: .5625rem;"
              v-model="boundData[item.fieldName]"
              type="number"
              :placeholder="item.defaultContent"
          />
        </van-row>
      </van-row>
      <van-row class="profession" style="margin-bottom: 0.90625rem" gutter="15">
        <van-col span="12"
        >
          <van-button round type="default" size="large" @click="reSet"
          >重置选项
          </van-button
          >
        </van-col
        >
        <van-col span="12"
        >
          <van-button
              round
              color="linear-gradient(to right, rgb(255, 112, 77), rgb(247, 55, 39))"
              size="large"
              @click="onSearch()"
          >搜索
          </van-button
          >
        </van-col
        >
      </van-row>
    </van-popup>

    <!-- <van-search
        v-model="key_word"
        show-action
        placeholder="请输入关键词搜索"
        @search="onSearch(1)"
    >
      <template #action>
        <div @click="onSearch(1)">搜索</div>
      </template>
    </van-search> -->

    <!-- 添加购买仙玉推荐  @scroll='addPayScroll' -->
    <!-- <van-sticky :offset-top="40" @scroll='addPayScroll'>
      <div class="addPayRecommend" v-show="isAddPayRecommend">
        <ul class="payRecommend">
          <li class="payRecommend_img">
            <img src="../../assets/images/人物@2x.png" alt="人物"/>
          </li>
          <li class="payRecommend_span">
            <span>兄弟想买多少仙玉呢？</span><br/>
            <span>让我帮你挑挑性价比高的仙玉怎么样~</span>
          </li>
          <li class="payRecommend_btn">
            <van-button type="primary" v-if="orderSwitch==1" @click="payRecommendOPen">{{
                this.openOrBackName == false ? "展开" : "收回"
              }}
            </van-button>
            <van-button type="primary" v-else @click="screenClick" >
                筛选
            </van-button>
          </li>
        </ul>
      </div>
      </van-sticky> -->

      <!-- <div v-if="orderSwitch==1">
      <div class="payRecommend_select" v-show="openOrBackName">
        <div class="payRecommend_select_content" v-show="isAddPayRecommend">
          <van-grid direction="horizontal" :column-num="4" :gutter="10">
            <van-grid-item
                v-for="(item, index) in RecommendNumList"
                :key="index"
                class="payRecommend_item"
                @click="payRecommendItemHandel(item)"
            >
              <img
                  class="payRecommend_item_img"
                  src="../../assets/images/仙玉11.png"
                  alt="仙玉"
              />
              <span >{{ item }}万</span>
            </van-grid-item>
            <van-grid-item class="payRecommend_item" @click="selfHandel">
              <span >自定义</span>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
      </div> -->

      <!-- <div v-if="orderSwitch==0">
      <div class="payRecommend_select1" v-show="openOrBackName">
        <div class="payRecommend_select_content1" v-show="isAddPayRecommend">
          <van-grid direction="horizontal" :column-num="2" :gutter="25" style="padding-left:30px">
            <van-grid-item
                v-for="(item, index) in RecommendNumList"
                :key="index"
                class="payRecommend_item1"
                @click="payRecommendItemHandel(item)"
            >
              <img
                  class="payRecommend_item_img1"
                  src="../../assets/images/仙玉11.png"
                  alt="仙玉"
              />
              <span  class="payRecommend_item_span">{{ item }}万仙玉</span>
            </van-grid-item>
            <van-grid-item class="payRecommend_item1" @click="selfHandel">
              <span class="payRecommend_item_span">自定义</span>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
      </div> -->


    <!-- <div style="width:100%;height:50px"></div> -->
    <!--自定义num弹框  -->
    <!-- <div class="dialogInput">
      <van-dialog
          v-model="isSelfNum"
          title="想买多少仙玉"
          show-cancel-button
          :confirmButtonColor="payRecommendNum == '' ? '#000' : '#ff4e00'"
          @confirm="surePayNumHandel"
      >
        <div class="payRecommendBox">
          <van-field
              v-model="payRecommendNum"
              type="digit"
              input-align="right"
          />
          <span class="payRecommendSpan">万</span>
        </div>
        <p style="font-size:0.8rem;color:#e02d43;text-align:left;padding-left:3rem;">*单笔购买仙玉数额最大{{ numTop }}万</p>
      </van-dialog>
    </div> -->

    <!-- 仙玉购买组合比例不合理提示 -->
    <!-- <div class="dialogGoPay1">
      <van-dialog
          v-model="isEndGoPay1"
          title="温馨提示"
          show-cancel-button
          cancelButtonText="重新选择"
          :confirmButtonText="'去支付' + ' (' + downTime + 's' + ')'"
          confirmButtonColor='#ff4e00'
          @confirm="goPayHandel1"
          @cancel="cancelGoPayHandel1"
      >
        <span style="font-size: 15px;margin: 9px;">你购买的订单中，存在低于平均成交比例的订单是否要继续购买?</span>

      </van-dialog>
    </div> -->
     <!-- 选择支付方式 -->
    <van-dialog
      class="choosePay"
      v-model="payshow"
      :show-confirm-button="false"
    >
      <div class="close"><i @click="payshow = false"></i></div>
      <div class="title">选择支付方式</div>
      <ul class="choosePay_list">
        <li @click="choosePya('1')">
          <img src="../../assets/images/alipay_pay.png" />
          <div class="blue">支付宝支付</div>
        </li>
        <li @click="choosePya('2')">
          <img src="../../assets/images/yue.png" />
          <div>
            <div class="orange">余额支付</div>
            <div class="yue">
              <span>账户可用余额为：</span>
              <span class="orange">{{ accountBalance }}元</span>
            </div>
          </div>
        </li>
      </ul>
    </van-dialog>
    <van-dialog
      class="choosePay password_input"
      v-model="input_show"
      title=""
      :show-confirm-button="false"
    >
      <div class="close"><i @click="input_show = false"></i></div>
      <div class="title">请输入支付密码</div>
      <van-password-input
        :value="password"
        :focused="showKeyboard"
        @focus="showKeyboard = true"
      />
      <!-- <div class="password_forget">
                <router-link :to="{ name: 'Passwordset', params: { active: 1}}"><span class="blue">忘记密码</span></router-link>
            </div> -->
      <div class="affirm_pay">
        <van-button
          :disabled="password.length !== 6"
          round
          color="linear-gradient(to right, rgb(255, 112, 77), rgb(247, 55, 39))"
          size="large"
          @click="confirm_payment"
          >确认支付</van-button
        >
      </div>
    </van-dialog>
     <van-number-keyboard
      v-model="password"
      :show="showKeyboard"
      :maxlength="6"
      @blur="showKeyboard = false"
    />
  </div>
</template>
<script>
import Top from "@/components/top";
import base from "@/api/base";
import { debounce } from "@/assets/js/utils.js";
export default {
  name: "home",
  components: {
    Top,
  },
  data() {
    return {

      value: '',
      columns: ['1.8版', '2.0版'],
      showPicker: false,

      isSelectGameShow:false,
      isSelectGameBack:true,
      loading: true,
      gameLinkList: [],
      // isshow: false,
      closeNullWarn:true,
      isAddPayRecommend: true,
      scale_goods:[],
      isShowServeStatus:false,
      can_pay_scope:'',
      regioneStr:'',
      serve_status:'',
      goods_type_list:[],
      showTransactionRole:false,
      filter_type_isActive:'2',
      xian_yu_scale:'',
      xian_yu_item_num:'',
      xian_yu_num:'',
      need_pay_num:"",
      isSelfNum: false,
      isEndGoPay: false,
      total_game_name:  '无限刀',
      show_pay_scope:false,
      transactionRoleData:{},
      currencyName:'',

      debounce,
      paymoney: 0,
      goods_str:'',
      input_show: false,
      payshow: false,
      showKeyboard: false,
      password: "",
      showKeyboard: false,
      accountBalance: "",
      orderSwitch: '',
      downHandel: 0,
      payRecommendNum: "",
      RecommendNumList: [1, 2, 5, 10, 20, 30, 50],
      numTop: '',
      OrderRouterList: [],
      downTime: "",
      isEndGoPay1: false,
      isDownTime: "",
      filter_type: "",
      defaultImg: require("../../assets/images/question.png"),
      show: false,
      key_word: "",
      profession: 1,
      active: "home",
      isActive: 1,
      loading: false,
      finished: false,
      error: false,
      currencyForm: {
        editionPicker:false,
        edition:'',
        editionData:['1.8版本', '2.0版本'],
        game_edition:'',
        channelId: "",
        region: "",
        regione_id: "",
        regions: [],
        gamePicker: false,
        server: "",
        server_id: "",
        serverPicker: false,
        servers: [],
        role: "",
        seedname: "",
        rolePicker: false,
        roles: [],
      },
      formDate: {
        game: "",
        games: [],
        classify: "",
        onsale_type: "",
        classifys: [],
        classifyShowPicker: false,
        channel_id: "",
        gameShowPicker: false,
        price_min: "",
        price_max: "",
        lv_min: "",
        lv_max: "",
        zs_lv_min: "",
        zs_lv_max: "",
        region: "",
        regions: [],
        regione_id: "",
        regionShowPicker: false,
        server: "",
        servers: [],
        server_id: "",
        serverShowPicker: false,
        big_demon: "",
        little_demon: "",
        professionCheck: ["0"],
        checklist: [
          {
            name: "all",
            title: "不限",
          },
          {
            name: "100",
            title: "战士",
          },
          {
            name: "102",
            title: "道士",
          },
          {
            name: "101",
            title: "法师",
          },
        ],
        professionCheck1: [],
        checklist1: [
          {
            name: "100",
            title: "武器",
          },
          {
            name: "101",
            title: "衣甲",
          },
          {
            name: "102",
            title: "手镯",
          },
          {
            name: "103",
            title: "戒指",
          },
          {
            name: "104",
            title: "项链",
          },
          {
            name: "105",
            title: "勋章",
          },
          {
            name: "106",
            title: "佩饰",
          },
          {
            name: "107",
            title: "腰带",
          },
          {
            name: "108",
            title: "鞋子",
          },
          {
            name: "109",
            title: "主印",
          },
          {
            name: "110",
            title: "辅印",
          },
          {
            name: "111",
            title: "宝珠",
          },
        ],
        checkboxGroup: [],
      },
      list: [],
      total: 0,
      page: 1,
      openOrBackName: true,
      isSearch: false,
      loadcheck: false,
      refreshing: false,
      dialogShow: false,
      dialogChecked: false,
      noticeHtml: "",
      clickoverlay: false,
      setKeyboard: false,
      noticeType: 1,
      noticeSrc: "",
      priceCheck: 1,
      priceCheck1: 2,
      priceCheck2: 3,
      formlist: {
        input_box: [],
        single_button: [],
        multi_select_button_fillet: [],
        multi_select_btn_right_angle: [],
        input_box_title: [],
        check_box: [],
        long_input_box: [],
      },
      boundData: {},
      istype: 1,
      noMore:"没有更多了",
      gameIsShow:true,
      newLink:base.newLink,
      listPollingTimer:null,
      tradingRules:''
    };
  },

    // beforeRouteEnter(to, from, next) {
    // // console.log("to", to);
    // // console.log("from", from);
    // if (from.name == "XianYuOrder") {
    //   next((vm) => {
    //     if (vm.downHandel == 1) {
    //       // console.log("支付成功", vm.downHandel);
    //       vm.downHandel = 0;
    //     } else {
    //       vm.cancelGoPayHandel();
    //       // console.log("取消订单", vm.downHandel);
    //       vm.downHandel = 0;
    //     }
    //   })
    // }else{
    //   next()
    // }
    // },
     beforeRouteLeave(to, from, next) {
    // console.log('beforeRouteLeave-to',to);
    // console.log('beforeRouteLeave-form',from);
    // console.log(from.fullPath);
    from.query.downHandel = 0;
    next();
  },
    created() {
       //防抖动
       this.click = this.debounce(this.payRequest, 500);
        //清除支付宝支付留下的元素
        let divnewForm = document.getElementsByTagName('divnewform');
        if (divnewForm.length) {
            document.body.removeChild(divnewForm[0]);
        };
        let arr;
        if(sessionStorage.getItem('type')){
            // this.isshow = false
            arr = sessionStorage.getItem('type')
            if(arr==0){
              this.total_game_name = "仙境传奇"
            }
            this.getChannel(arr);
            // arr = localStorage.getItem('type')
        }else{
          this.getChannel();
            // this.isshow = true
        };
        // if(this.$route.query.a){
        //     let arr = this.$Base64.decode(this.$route.query.a)
        //     arr = JSON.parse(arr)
        //     this.$api.login.loginIn(arr).then(res => {
        //         if (res.code == 200) {
        //             localStorage.setItem('token', 'Bearer '+ res.data.token);
        //             localStorage.setItem('username', arr.username);
        //         }
        //         this.gameChannelList();
        //         this.getChannel();
        //     }).catch(err => {
        //         this.gameChannelList();
        //         this.getChannel();
        //     });
        // }else{
        //获取游戏渠道列表数据
        this.gameChannelList();
        this.getBalance();
        // this.getConfigBuyCoin();
        // this.regionList();
        // let data = {
        //     channel_id:'',//	是	string	渠道id
        //     filter_type:'',//	是	string	筛选分类 1 角色 2 货币 3 装备
        // }
      this.gameingData()
    },
    mounted(){
        this.$nextTick(function () {
            window.addEventListener('focusin', () => {
                // var resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
                // alert(resizeHeight);
                // 键盘弹出事件处理
                //this.$store.commit('setKeyboard', true);
                //alert('键盘弹起')
                this.setKeyboard = true;
            });
            window.addEventListener('focusout', () => {
                // 键盘收起事件
                //this.$store.commit('setKeyboard', false);
                // var resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
                // alert('键盘关闭')
                setTimeout(() => {
                    this.setKeyboard = false;
                }, 200);
            });

        })
    },
  methods: {
    //游戏中自动加载参数
    gameingData(){
      let data = JSON.parse(sessionStorage.getItem("a"));
      if (!data){
        //不是游戏中进则判断是否已选择游戏
        this.isSelectGameShow = localStorage.getItem('isSelectGameShow') ? JSON.parse(localStorage.getItem('isSelectGameShow')): true
        if (localStorage.getItem('game_items')) {
          this.isSelectGameBack=true
          this.getGameId(JSON.parse(localStorage.getItem('game_items')))
        }else{
          this.isSelectGameShow=true
          this.isSelectGameBack=false
        }
        return false
      }
      this.gameIsShow = false
      let regione_id = Math.floor(data.server_id/1000)
      // regione_id= 5
      if (data.type==33) {
        //无限刀2
        this.total_game_name = "无限刀Ⅱ"
      }else if(data.type ==9){
        this.total_game_name = "时光倒流";
      }
      //获取货币交易规则
      this.getRule()
      this.$api.shopping.regionList({
        channel_id: data.type,
      }).then((res) => {
        // this.isLoading = false;
        if (res.code == 200) {
          res.data.forEach(item=>{
            if(item.regione_id==regione_id){
              this.currencyForm.region = item.regione_name;
              this.currencyForm.regione_id = item.regione_id;
            }
          })
          this.$api.helper.getServerList({
            channel_id: data.type,
            regione_id:regione_id
          }).then((res1) => {
            // console.log(res1)
            // return
            if (res1.code == 200) {
              res1.data.forEach(item=>{
                if(item.server_id==data.server_id){
                  // console.log(item)
                  this.currencyForm.server = item.server_name;
                  this.currencyForm.server_id = item.server_id;
                  this.currencyForm.regione_id = item.regione_id;
                }
              })
              this.$api.helper.getPlayerList({
                  channel_id: data.type,
                  server_id: this.currencyForm.server_id,
                  type: 1,
                }).then((res2) => {
                  if (res2.code == 200) {
                    this.currencyForm.roles =res2.data
                    res2.data.forEach(item=>{
                      if(item.seedname==data.seedname){
                        this.currencyForm.role = item.chrname;
                        this.currencyForm.seedname = item.seedname;
                        this.getScaleGoods()
                        //开启货币轮询
                        this.listPolling()
                      }
                    })
                  }
                }).catch((err) => {
                  console.log(err);
                });
            //  sole.log(222,this.currencyForm)
              }
          })
        }
        // }
      }).catch((err) => {
        console.log(err);
      });
    },

    //获取仙玉规则
    getConfigBuyCoin(){
     this.$api.shopping.config_buy_coin().then((res)=>{
      //  console.log(this.formDate.channel_id);
       this.transactionRoleData=res.data
     })
    },
    //点击每一条仙玉列
    clickXianYuItem(item){
      this.xian_yu_scale=item.rebate;
      this.xian_yu_item_num=item.coin_num;
      this.xian_yu_num=1;
      this.need_pay_num=''
      // console.log(item);
      this.isSelfNum=true
    },
    //显示游戏选择
    showPopup(){
      this.isSelectGameShow=true
    },
    // 获取商品分类列表
    getGoodsType(data, type) {
      this.$api.shopping.GoodsType(data).then((res) => {
         let data=res.data;
         data.forEach((item)=>{
           switch (item.filter_type) {
                    case '1':
                      item.src = require("../../assets/images/box_update/role@2x.png");
                      break;
                    case '2':
                      item.src = require("../../assets/images/box_update/xian_yu_total@2x.png");
                      break;
                    case '3':
                      item.src = require("../../assets/images/box_update/equip@2x.png");
                      break;
                  }
         })
         this.goods_type_list=data
      });
    },
    //游戏名称列表
    gameChannelList() {
      // console.log(base.bd)
      this.$api.shopping.gameChannelList({
            dev: localStorage.getItem("ip"),
          }).then((res) => {
            // console.log(res.data);
            if (res.code == 200 && res.data.length > 0) {
                res.data.forEach((item,index)=>{
                  if(item.channel_id==24){
                    res.data.splice(index,1)
                  }
                })
              this.gameLinkList = [];
              res.data.forEach((item) => {
                item.name = item.channel_name;
                item.url = item.web_url;
                item.channel_id = item.channel_id;
                // console.log(item.img_url)
                if (item.img_url) {
                  // console.log(111)
                  item.src = base.bd + "/" + item.img_url;
                } else {
                  switch (item.channel_id) {
                    case 0:
                      item.src = require("../../assets/images/more_heard1.png");
                      break;
                    case 18:
                      item.src = require("../../assets/images/more_heard4.png");
                      break;
                    case 24:
                      item.src = require("../../assets/images/more_heard2.png");
                      break;
                    case 9:
                      item.src = require("../../assets/images/more_heard3.png");
                      break;
                  }
                }
                this.gameLinkList.push(item);
              });
            }
          }).catch((err) => {
            console.log(err);
          });
    },
    //首页是否合区展示
    getMergeServer(){
      let params={
        server_id:this.currencyForm.server_id,
        regione_id:this.currencyForm.regione_id,
        channel_id:this.formDate.channel_id
      }
      this.$api.shopping.is_merge_server(params).then((res)=>{
        if(res.code==200){
          this.can_pay_scope = res.data.server_str;
          this.regioneStr=res.data.regioneStr;
          this.serve_status = res.data.is_merge == 0 ? '未合区' : '已合区'
        }
      })
      // .catch((error)=>{
      //   this.can_pay_scope = '暂无支持大区';
      //   this.serve_status = '无'
      // })
    },
     //点击游戏logo
    getGameId(item) {
    // console.log(item);
      // this.finished = false;
      // this.error = false;
      // this.list = [];
      // this.page = 1;
      // this.loading = true;
      //清空货币列表轮询
      this.clearListPollingTimer()
      this.currencyName=item.channel_id==0?'元宝':'仙玉'
      this.isSelectGameBack=true
      localStorage.setItem('game_items',JSON.stringify(item))
      this.total_game_name=item.name;
      this.isSelectGameShow=false;//抽屉关闭
      this.formDate.channel_id = item.channel_id;
      // console.log('213232',this.formDate.channel_id);
      this.currencyForm.edition='';

      this.formDate.regione_id='';
      this.formDate.server_id='';
      this.formDate.region = "";
      this.formDate.server = "";
      this.currencyForm.region='';
      this.currencyForm.server='';
      this.currencyForm.role='';
      this.currencyForm.regions=[];
      this.currencyForm.servers=[];
      this.currencyForm.roles=[];
      this.regionList();
      if (item.channel_id=='18' || item.channel_id=='33') {
        this.formDate.onsale_type='2'
        this.regionList1();
      }else{
        this.formDate.onsale_type='1'
      }
      // console.log(this.formDate.channel_id);
      // console.log(this.formDate.game);
      // console.log(this.formDate.classify);
      this.getGoodsType({channel_id: this.formDate.channel_id});
      //获取交易规则
      this.getRule()
      //重新加载数据
      //将 loading 设置为 true，表示处于加载状态
      this.list = [];//清空列表
      this.finished = false;
      this.error = false;
      this.loading = true;
      this.page = 1;
      this.onLoad();
      let data = {
        channel_id: this.formDate.channel_id,
        filter_type: this.formDate.onsale_type,
      };
      this.getFilterInfo(data); //获取搜索信息

      if (localStorage.getItem("token")) {
        this.$api.shopping.promote({
              click_channel_id: item.channel_id,
            }).then();
      };
      //请求公告
       this.getNotice();
      // this.formDate.region='';
      // this.formDate.server='';
      // this.regionList();
    },
    //交易规则
    transactionRoleHandle(){
      this.showTransactionRole=true;
    },
    //货币比例数量列表
    getScaleGoods(){
      let params={
        channel_id:this.formDate.channel_id,
        server_id:this.currencyForm.server_id,
        regione_id:this.currencyForm.regione_id,
        type:this.filter_type_isActive
      };
       this.$api.shopping.getScaleGoods(params).then((res)=>{
         if (res.code==200) {
           this.scale_goods=res.data
         }
       })
    },
//选择框选择顺序提示
    changeClick1(key) {
      // editionPicker:false,
      //   edition:'',
      //   editionData:['1.8版', '2.0版'],
      //   game_edition:'',
      if (!this.gameIsShow) {
        //游戏内不让选择
        if (key == 1 || key == 2 || key == 3 || key == 0) {
          return false;
        }
      }
      if (this.formDate.channel_id==0) {
        //冰雪
        if (key == 1) {
          if (this.currencyForm.edition) {
          this.currencyForm.gamePicker = true;
        } else {
          this.$toast({
            message: "请先选择游戏版本，再选大区",
            duration: 1500,
          });
        }
        }else if(key == 2) {
        if (this.currencyForm.region) {
          this.currencyForm.serverPicker = true;
        } else {
          this.$toast({
            message: "请先选择大区，再选区服",
            duration: 1500,
          });
        }
      } else if (key == 3) {
        if (this.currencyForm.server) {
          this.currencyForm.rolePicker = true;
        } else {
          this.$toast({
            message: "请先选择区服，再选角色",
            duration: 1500,
          });
        }
      }else if(key == 0){
        this.currencyForm.editionPicker = true;
      }

      }else{
        //无限刀
        if (key == 2) {
        if (this.currencyForm.region) {
          this.currencyForm.serverPicker = true;
        } else {
          this.$toast({
            message: "请先选择大区，再选区服",
            duration: 1500,
          });
        }
      } else if (key == 3) {
        if (this.currencyForm.server) {
          this.currencyForm.rolePicker = true;
        } else {
          this.$toast({
            message: "请先选择区服，再选角色",
            duration: 1500,
          });
        }
      }else if(key == 1){
        this.currencyForm.gamePicker = true;
      }
      }
      // if (key == 2) {
      //   if (this.currencyForm.region) {
      //     this.currencyForm.serverPicker = true;
      //   } else {
      //     this.$toast({
      //       message: "请先选择大区，再选区服",
      //       duration: 1500,
      //     });
      //   }
      // } else if (key == 3) {
      //   if (this.currencyForm.server) {
      //     this.currencyForm.rolePicker = true;
      //   } else {
      //     this.$toast({
      //       message: "请先选择区服，再选角色",
      //       duration: 1500,
      //     });
      //   }
      // }else if(key == 1){
      //   this.currencyForm.gamePicker = true;
      // }
    },
    //选择框确认
    onConfirm1(value) {
        //清空货币列表轮询
        this.clearListPollingTimer()
      if(this.formDate.channel_id==0){
        // console.log(value);
        //冰雪
        if (this.currencyForm.editionPicker) {
          this.currencyForm.edition=value;
          this.currencyForm.game_edition=value=='1.8版本' ? '0' : '1'
          this.currencyForm.region = '';
          this.currencyForm.regions = [];
          this.currencyForm.regione_id='';
          this.currencyForm.server = "";
          this.currencyForm.servers = [];
          this.currencyForm.server_id=''
          this.currencyForm.role = "";
          this.currencyForm.roles = [];
          this.currencyForm.seedname='';
          this.currencyForm.editionPicker = false;
          // console.log(this.params)
          this.regionList1(this.currencyForm.game_edition)
        }else if (this.currencyForm.gamePicker) {
          this.currencyForm.region = value.regione_name;
          this.currencyForm.regione_id = value.regione_id;
          this.currencyForm.server = "";
          this.currencyForm.servers = [];
          this.currencyForm.server_id=''
          this.currencyForm.role = "";
          this.currencyForm.roles = [];
          this.currencyForm.seedname='';
          this.currencyForm.gamePicker = false;
          // console.log(this.params)
          this.serverList1(value.regione_id);
      } else if (this.currencyForm.serverPicker) {
          this.currencyForm.server = value.server_name;
          this.currencyForm.server_id = value.server_id;
          this.currencyForm.role = "";
          this.currencyForm.roles = [];
          this.currencyForm.seedname='';
          this.currencyForm.serverPicker = false;
          this.roleList1(this.currencyForm);
      } else {
          this.currencyForm.role = value.chrname;
          this.currencyForm.seedname = value.seedname;
          this.currencyForm.rolePicker = false;
          this.getScaleGoods()
          //开启货币轮询
          this.listPolling()
        }

      }else{
        //无限刀
        if (this.currencyForm.gamePicker) {
        this.currencyForm.region = value.regione_name;
        this.currencyForm.regione_id = value.regione_id;
        this.currencyForm.server = "";
        this.currencyForm.servers = [];
        this.currencyForm.server_id=''
        this.currencyForm.role = "";
        this.currencyForm.roles = [];
        this.currencyForm.seedname='';
        this.currencyForm.gamePicker = false;
        // console.log(this.params)
        this.serverList1(value.regione_id);
      } else if (this.currencyForm.serverPicker) {
        this.currencyForm.server = value.server_name;
        this.currencyForm.server_id = value.server_id;
        this.currencyForm.role = "";
        this.currencyForm.roles = [];
         this.currencyForm.seedname='';
        this.currencyForm.serverPicker = false;
        this.roleList1(this.currencyForm);
      } else {
        this.currencyForm.role = value.chrname;
        this.currencyForm.seedname = value.seedname;
        this.currencyForm.rolePicker = false;
        this.getScaleGoods()
        //开启货币轮询
        this.listPolling()
      }
      }

    },
    //根据渠道获取大区列表
    regionList1(game_edition) {
      let edition=game_edition?game_edition:''
      this.$api.shopping.regionList({
          channel_id: this.formDate.channel_id,
          game_edition:edition
        }).then((res) => {
          // this.isLoading = false;
          if (res.code == 200) {
            // console.log(res.data)
            this.currencyForm.regions = res.data;
            //首次点开默认值
            // console.log(this.state)
            // if (this.state == 1) {
              // this.currencyForm.region = this.detailData.regione_name;
              // this.currencyForm.regione_id = this.detailData.regione_id;
              this.currencyForm.channelId = this.formDate.channel_id;
              // if (this.currencyForm.channelId) {
              //   console.log('2',this.currencyForm.regione_id);
              //   this.serverList1(this.currencyForm.regione_id);
              // }
            }
          // }
        }).catch((err) => {
          console.log(err);
        });
    },
    //根据渠道获取区服列表
    serverList1(regione_id) {
      // console.log('1',regione_id);
      this.$api.helper.getServerList({
          channel_id: this.formDate.channel_id,
          regione_id:regione_id
        }).then((res) => {
          if (res.code == 200) {
            for (var key in res.data) {
              this.currencyForm.servers.push({
                server_name: res.data[key].server_name,
                server_id: res.data[key].server_id,
              });
            }
            }
        }).catch((err) => {
          console.log(err);
        });
    },
    //根据区服获取角色列表
    roleList1(form) {
      // console.log(111,from)
      this.$api.helper.getPlayerList({
          channel_id: form.channelId,
          server_id: form.server_id,
          type: 1,
        }).then((res) => {
          if (res.code == 200) {
            // if (this.goods_type == 2) {
              this.currencyForm.roles = res.data;
          }
        }).catch((err) => {
          console.log(err);
        });
    },
    //点击货币/角色/装备
    async goodsTypeClick(filter_type){
      //清空货币列表轮询
      this.clearListPollingTimer()
      if (this.filter_type_isActive != filter_type) {
        if (this.loading) {
        // this.$toast({
        //      message: "请在数据加载完成后切换",
        //      duration: 1500,
        //     });
        return
      }
      }
      //channel_id除18/33 为2-仙玉 4-元宝，其余游戏都为 2-元宝
      if (this.formDate.channel_id==18 || this.formDate.channel_id==33) {
        this.currencyName=filter_type=='4'?'元宝':'仙玉'
      }else{
        this.currencyName='元宝'
      }
      this.filter_type_isActive = filter_type;
      this.formDate.onsale_type = filter_type;
      if (filter_type=='2' || filter_type=='4') {
        this.isAddPayRecommend=true
        if (JSON.parse(sessionStorage.getItem("a"))) {
          //游戏中
          this.gameingData()
        }else{
          //非游戏中
          this.currencyForm.region = '';
          this.currencyForm.regione_id='';
          this.currencyForm.server = "";
          this.currencyForm.servers = [];
          this.currencyForm.server_id=''
          this.currencyForm.role = "";
          this.currencyForm.roles = [];
          this.currencyForm.seedname='';
        // channel_id: "",
        // gameShowPicker: false,
        // region: "",
        // regions: [],
        // regione_id: "",
        // regionShowPicker: false,
        // server: "",
        // servers: [],
        // server_id: "",
        // serverShowPicker: false,
        }
       
      }else{
        this.isAddPayRecommend=false;
        let data = {
        channel_id: this.formDate.channel_id,
        filter_type: filter_type,
      };
      this.finished = false;
      this.error = false;
      this.loading = true;
      this.list = [];//清空列表
      this.page = 1;
      await this.getFilterInfo(data); //获取搜索信息
      this.onLoad()
      }
      // console.log(this.formDate.onsale_type);
    },
    //获取仙玉需支付金额
    saleInput(num){
      // console.log(num);
      // if(num.length == 1) {
      //     this[key] = this[key].replace(/[^1-9]/g, '');
      //     console.log(11111);
      //   }
      this.need_pay_num =  Math.floor(Number(num * 10000 / this.xian_yu_scale)*100 )/100;
      if( num > this.xian_yu_item_num ){
          this.$toast({
             message: "当前输入"+this.currencyName+"数量超出可购买挂单数，请重新输入"+this.currencyName+"数量！",
             duration: 1500,
            });
      }
    },
    // 有金额确认按钮/倒计时
    surePayNumHandel() {
      // isSelfNum
      // console.log(this.xian_yu_num,this.xian_yu_item_num);
      if (this.xian_yu_num==0) {
        this.$toast({
             message: "输入购买"+this.currencyName+"数量不能为空，且最少1万！",
             duration: 1500,
            });
            return false
      }
        this.getBalance(); //获取余额
        if( this.xian_yu_num > this.xian_yu_item_num ){
         this.$toast({
             message: "当前输入"+this.currencyName+"数量超出可购买挂单数，请重新输入"+this.currencyName+"数量！",
             duration: 1500,
            });
         }else{
       if (this.xian_yu_num != "" && this.xian_yu_num != 0) {
        // if (this.payRecommendNum <= this.numTop) {
           let params = {
               coin_num: this.xian_yu_num,
               server_id: this.currencyForm.server_id,
               regione_id: this.currencyForm.regione_id,
               channel_id: this.formDate.channel_id,
               scale:this.xian_yu_scale,
               onsale_type:this.filter_type_isActive
           };
          //  console.log(params);
          //  return false
           this.$api.pay.combinationPay(params).then((res) => {
            // console.log("有金额时提交的订单信息", res);
            //将订单信息存入本地，方便调用释放订单方法
            // console.log(res);
            //仙玉数量暂无匹配
            if (res.code==1) {
              this.$toast({
                      message: res.msg,
                      duration: 1500,
                    });
            }else{
              //仙玉数量有匹配
              //匹配仙玉和库存订单不足
              let arr = res.data.data.map((item) => {
                     return item.goods_id;
                  });
            this.goods_str= arr.toString();
            sessionStorage.setItem('orderData',JSON.stringify(res.data.data))
            if (this.xian_yu_num != res.item_num) {
              // console.log('匹配仙玉不够');
              this.isEndGoPay = true;
            this.OrderRouterList = res;
            this.downTime = res.count_down;
            if (res.code == 0) {
              //判断是否被锁单，锁单过多是msg提示，此时不弹弹窗
              if (res.msg) {
                this.isEndGoPay = false;
              } else {
                this.isEndGoPay = true;
                this.isDownTime = setInterval(() => {
                  this.downTime--;
                  // console.log(this.downTime);
                  if (this.downTime < 1) {
                    this.isEndGoPay = false;
                    clearInterval(this.isDownTime);
                    this.$toast({
                      message: "订单锁定超时，请重新选择金额",
                      duration: 1500,
                    });
                  }
                }, 1000);
              }
            } else {
              this.isEndGoPay = false;
            }
            }else{
              //匹配仙玉够额
              // this.getBalance();
              // console.log('匹配仙玉够');
              this.payshow=true
            }
            }
          });
            } else {
              // this.xian_yu_num = ""
              this.$toast({
                      message: "请先输入数量",
                      duration: 1500,
                    });
              return false
            }
         }
        //  console.log(this.isSelfNum);
    },
    // 有金额确认按钮/倒计时关闭之前
    onBeforeClose(action, done){
      //  console.log(action,done);
       // 点击了确定按钮
    if (action === "confirm") {
    	// if()...也可以加入一些条件
        if( this.xian_yu_num > this.xian_yu_item_num || this.xian_yu_num=='' ||this.xian_yu_num==0){
          return done(false);
        }else{
          return done(true);
        }
        //直接return它即可阻止
    }
    // 点击了取消按钮
    else {
        done(true); //关闭弹窗, true可以省略
    }
    },
    //取消去支付按钮
    cancelGoPayHandel() {
      //清除有金额订单锁定定时器
      clearInterval(this.isDownTime);
      let arr = JSON.parse(sessionStorage.getItem('orderData')).map((item) => {
        return item.goods_id;
      });
      let params = {ids: arr.toString()};
      this.$api.pay.cancelCombine(params).then((res) => {
        // console.log(res);
        this.isEndGoPay = false;
        // this.isEndGoPay1 = false;
        sessionStorage.removeItem('orderData');
      });
    },
    // 去支付确认按钮
    goPayHandel() {
       this.getBalance();
      //清除有金额订单锁定定时器
      clearInterval(this.isDownTime);
      // let DataList = JSON.stringify(this.OrderRouterList);
      // if (this.orderSwitch == 1) {
      //   if (this.OrderRouterList.code == 0) {
      //   if (this.OrderRouterList.is_exist == 1) {
      //     this.isEndGoPay1 = true
      //   } else {
      //     // this.$router.push({
      //     //   path: '/xianYuOrder',
      //     //   query: {data: JSON.stringify(this.OrderRouterList)},
      //     // });
      //     // this.isEndGoPay = false;
      //   }
      // }
      // }else{
        this.payshow=true
      // }

    },

    //选择游戏的返回
    // selectGameBack(){
    //   this.isSelectGameShow=false
    // },
    // 确认支付按钮
    confirm_payment() {
      // console.log(this.paymoney)
      this.$toast({
        duration: 1500,
        message: "支付中······",
      });
      this.$api.shopping
        .checkPayPassword({
          payPw: this.password,
        })
        .then((res) => {
          if (res.code == 200) {
            // console.log(this.paymoney)
            //
            if (this.paymoney == 0) {
              //  余额支付
              this.paySubmit();
            } else {
              this.$toast({
                duration: 1500,
                message: "余额不足",
              });
              return false;
              // 混合支付
              let data = {
                  number: this.paymoney,
                  checked: 1,
                  goods_str: this.goods_str,
                  is_combination: 1,
                },
                dataObj = {};
              // console.log(data)
              if (this.appData.server_id) {
                dataObj = data;
                dataObj.to_server = this.currencyForm.server_id;
                dataObj.chrname = this.currencyForm.role;
              } else {
                dataObj = data;
              }
              // console.log(dataObj)
              this.$router.push({
                name: "paymentChannels",
                query: dataObj,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
     //余额支付
    paySubmit() {
      // let isbalance = this.checked ? 1 : 0,
      let data = {
          username: localStorage.getItem("username"),
          goods_str: this.goods_str,
          pay_type: "ali",
          type: "wap",
          is_balance: 1,
          is_combination: 1,
          total_coin:this.xian_yu_num
        },
        dataObj = {};
      if (this.currencyForm.server_id) {
        dataObj = data;
        dataObj.to_server = this.currencyForm.server_id;
        dataObj.chrname = this.currencyForm.role;
      } else {
        dataObj = data;
      }
      this.$api.shopping
        .createOrderAli(dataObj)
        .then((res) => {
          if (res.code == 200) {
            this.password=''
            //this.$toast.success('支付成功');
            this.input_show = false;
            // this.$router.push({
            //     name: 'Paysuccess',
            // });
            this.$toast({
              duration: 1500,
              message: "购买成功",
            });
            this.$router.push({
              path: "/",
              query: {
                downHandel: 1,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //查看支付是否成功
    checkOrder() {
      //每2s查询一次，直到获取到结果为止
      let timer;
      // console.log(123)
      timer = setInterval(() => {
        this.$api.shopping
          .checkOrder({
            goods_str: this.goods_str,
            is_combination: 1,
          })
          .then((res) => {
            //支付宝支付拿到结果后结束轮询
            clearInterval(timer);
            //针对微信支付拿到结果后清除存的值
            localStorage.removeItem("isPay");
            if (res.code == 200) {
              this.$toast({
                duration: 1500,
                message: "购买成功",
              });
              this.$router.push({
                path: "/",
                query: {
                  downHandel: 1,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 2000);
    },

    //获取账户余额
    getBalance() {
      let username = localStorage.getItem("username");
      this.$api.account.getBalance({ username: username }).then((res) => {
          if (res.code == 200) {
            this.accountBalance = res.data.accountBalance;
            this.frozenBalance = res.data.frozenBalance;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //判断是否已安全设置
    isSettig() {
      this.$api.account.isPaymentCode({ type: 1 }).then((res) => {
        if (res.code == 200) {
          if (res.data.length == 0) {
            this.isSet = false;
          } else {
            this.isSet = true;
          }
        }
      });
    },
    // 选择付款方式
    choosePya(type) {
      if (type == 1) {
        // 支付宝支付
        this.click();
        // this.$toast({
        //   duration: 1500,
        //   message: "暂未开通支付宝支付功能",
        // });
      } else {
        // 余额支付
        this.$api.account
          .getBalance({ username: localStorage.getItem("username") })
          .then((res) => {
            if (res.code == 200) {
              if (res.data.is_set_up == 0) {
                this.isSet = false;
                this.$toast({
                  duration: 1500,
                  message: "为保证您的账户安全，请先完成安全设置哦！",
                });
                this.$router.push("/safesetting");
              } else {
                this.isSet = true;
                // this.showKeyboard = true;//弹出键盘
                this.getMoney();
              }
            }
          });
      }
    },

    //提交支付请求
    payRequest() {
      // //微信支付成功后会新窗口打开页面，所以用本地存值的方法来判断是否发起订单结果请求
      localStorage.setItem("isPay", true);
      // let isbalance;
      // isbalance =  0;
      //支付宝支持成功后会返回到原界面，所以支付后原界面需要通过轮询的方法来判断是否发起订单结果请求
      let param, contents, tok;
      tok = localStorage.getItem("token").substring(7);
      if (this.currencyForm.server_id) {
        // console.log(111)
        param = {
          username: localStorage.getItem("username"),
          goods_str: this.goods_str,
          is_combination: 1,
          pay_type: "alipay",
          type: "wap",
          is_balance: 0,
          token: tok,
          to_server: this.currencyForm.server_id,
          chrname: this.currencyForm.role,
          total_coin:this.xian_yu_num
        };
      } else {
        // console.log(222)
        param = {
          username: localStorage.getItem("username"),
          goods_str: this.goods_str,
          is_combination: 1,
          pay_type: "alipay",
          type: "wap",
          is_balance: 0,
          token: tok,
          total_coin:this.xian_yu_num
        };
      }
      // console.log(param,this.detailData)
      // return
      // console.log(param)
      // return
      contents = this.$Base64.encode(JSON.stringify(param));
      this.$api.shopping
        .createOrderAli({
          content: contents,
        })
        .then((res) => {
          // console.log(res);
          // return false
          //存放原始数据
          let divForm = document.getElementsByTagName("divform");

          if (divForm.length) {
            document.body.removeChild(divForm[0]);
          }

          const div = document.createElement("divform");
          div.innerHTML = res; // data就是接口返回的form 表单字符串
          document.body.appendChild(div);
          div.style.display = "none";
          //将提取出来的form元素放到新元素里
          // let divs = document.createElement("divnewform");
          // divs.innerHTML = "";
          // divs.appendChild(document.forms[0]);
          // document.body.appendChild(divs);
          // console.log(divs);
          // return
          //删除原始元素
          // document.body.removeChild(divForm[0]);
          //console.log(document.forms[0]);
          //document.forms[0].setAttribute('target', '_blank'); // 新开窗口跳转
          document.forms[1].submit();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //计算需要支付的金额
    getMoney() {
      //开启抵扣;
      this.$api.shopping
        .ableAllPay({
          goods_str: this.goods_str,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.able_all_balance_pay == 1) {
              this.paymoney = 0;
            } else {
              this.paymoney = this.accSub(this.data.money, this.accountBalance);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.input_show = true;
      this.payshow = false;
    },
    //精确计算小数减法结果
    accSub(arg1, arg2) {
      if (isNaN(arg1)) {
        arg1 = 0;
      }
      if (isNaN(arg2)) {
        arg2 = 0;
      }
      arg1 = Number(arg1);
      arg2 = Number(arg2);

      var r1, r2, m, n;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
      n = r1 >= r2 ? r1 : r2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },
    // getSingleOrderSwitch() {
    //   if(this.formDate.channel_id==18 && this.formDate.onsale_type==2){
    //     // this.isAddPayRecommend = true;
    //   }
    //   this.$api.shopping.getSingleOrderSwitch({channel_id:this.formDate.channel_id,type:this.formDate.onsale_type}).then((res)=>{
    //     this.orderSwitch = res.data
    //     if(this.orderSwitch==1){
    //       this.noMore = "";
    //     }
    //   })
    // },
    //仙玉自定义金额获取
    // gitNumTop() {
    //   this.$api.pay.configCombinationPay().then((res) => {
    //     // console.log(res.data.numTop);
    //     this.numTop = res.data.numTop
    //   })
    // },
    //仙玉交易比例不满足条件去支付弹窗
    // goPayHandel1() {
      // this.isEndGoPay = false;
      // this.isEndGoPay1 = false;
      //清除有金额订单锁定定时器
      // clearInterval(this.isDownTime);
      // if(this.orderSwitch==1){
      //   this.$router.push({
      //   path: '/xianYuOrder',
      //   query: {data: JSON.stringify(this.OrderRouterList)},
      // });
      // }else{
      //   // console.log(11111);
      // }

    // },
    //仙玉交易比例不满足条件点击取消
    // cancelGoPayHandel1() {
    //   //点击重新选择
    //   this.cancelGoPayHandel();
    //   //清除有金额订单锁定定时器
    //   clearInterval(this.isDownTime);
    // },
    //展开收回
    // payRecommendOPen() {
    //   this.openOrBackName = !this.openOrBackName;
    // },
    //推荐购买仙玉的吸顶效果事件
    // addPayScroll(e) {
    //   //  console.log('scroll',e);
    //   if(this.orderSwitch==1){
    //     this.openOrBackName = !e.isFixed
    //   }

    // },
    //推荐购买仙玉item点击事件
    // payRecommendItemHandel(item) {
    //   this.payRecommendNum = item;
    //   // this.surePayNumHandel();
    // },
    //点击自定义按钮事件
    // selfHandel() {
    //   this.isSelfNum = true;
    //   this.payRecommendNum = "";
    // },


    characterdetails(goods_id) {
      this.$router.push({
        name: "characterDetails",
        query: {
          channel_id: this.formDate.channel_id,
          goods_id: goods_id,
        },
      });
    },
    // 商品筛选框渲染
    async getFilterInfo(data, type) {
      // window
      this.clearHttpRequestingList();
      this.boundData = {};
      this.formlist = this.$options.data.call(this).formlist;
      await this.$api.shopping.FilterInfo(data).then((res) => {
        // console.log(res.data)
        for (var i in res.data) {
          // console.log(res.data[i])
          for (var s in res.data[i]) {
            // console.log(s)
            this.formlist[s] = res.data[i][s].data;
            // console.log(res.data[i][s].data)
            res.data[i][s].data.forEach((item, index) => {
              // console.log(item,index)
              let numericalOrder = 0;
              let isTrue = true;
              if (index + 1 > res.data[i][s].data.length - 1) {
                numericalOrder = index - 1;
              } else {
                numericalOrder = index + 1;
              }
              // console.log(item.fieldName ,res.data[i][s].data[numericalOrder].fieldName)
              // if(numericalOrder==index){

              // }
              if (
                  item.fieldName !== res.data[i][s].data[numericalOrder].fieldName
              ) {
                // this.boundData[item.fieldName] = ''
                this.$set(this.boundData, item.fieldName, "");
              } else {
                // this.boundData[item.fieldName] = []
                if (isTrue) {
                  isTrue = false;
                  this.$set(this.boundData, item.fieldName, []);
                }
              }
            });
          }
        }
        // console.log(this.boundData)
        // console.log(this.boundData.job)
        if (this.boundData.job) {
          var unwatch = this.$watch("boundData.job", function (val, oldVal) {
            if (!this.boundData.job) {
              unwatch();
            } else {
              if (val.indexOf("0") != -1 && oldVal.indexOf("0") == -1) {
                this.boundData.job = ["0"];
              } else if (val.length > 1 && val.indexOf("0") != -1) {
                this.boundData.job.splice(this.boundData.job.indexOf("0"), 1);
              }
            }
          });
        }

        if (type) {
          this.onSearch();
          // console.log(333)
        }
      });
    },

    //获取游戏版本列表
    getChannel(type) {
      this.$api.helper.getChannel().then((res) => {
        if (res.code == 200 && res.data.length > 0) {
          if (sessionStorage.getItem("type")==='24') {
            let arr_item = []
            res.data.forEach((item,index)=>{
              if(item.channelId==24){
                // console.log(res.data[index])
                // res.data = []
                // res.data.push(res.data[index])
                arr_item.push(res.data[index])
              }
            })
            res.data = arr_item
            // console.log(res.data)
          }else{
            res.data.forEach((item,index)=>{
              if(item.channelId==24){
                res.data.splice(index,1)
              }
            })
          }
          this.formDate.games = res.data;
          // console.log(this.formDate.games);
          //设置默认游戏选项
          let defaultId;
          // console.log(type , sessionStorage.getItem('type'))
          if (type === sessionStorage.getItem("type")) {
            defaultId = sessionStorage.getItem("type");
          }
          else {
            // defaultId = this.formDate.games[0].channelId;//默认选择游戏
            defaultId =  this.formDate.channel_id;
          }
          // console.log(defaultId,this.formDate.games)
          this.formDate.channel_id = defaultId;
          // 请求获取商品分类列表
          this.getGoodsType({channel_id: defaultId}, type);

          //请求公告
          if(sessionStorage.getItem('type')=='24'){

          }else{
            this.getNotice();
          }
          if (String(this.formDate.channel_id)) {
            var newArr = this.formDate.games.filter((item) => {
              // console.log(item.channelId,this.formDate.channel_id)
              return item.channelId == this.formDate.channel_id;
            });
            this.formDate.game = newArr[0].channelName;
            this.regionList();
          }
          //游戏头像展示
          // this.gameLinkList = [];
          // res.data.forEach((item) => {
          //     item.name = item.channelName;
          //     item.url = item.webUrl;
          //     switch (item.channelId) {
          //         case 0:
          //             item.src = require('../../assets/images/more_heard1.png');
          //             break;
          //         case 5:
          //             item.src = require('../../assets/images/more_heard4.png');
          //             break;
          //         case 6:
          //             item.src = require('../../assets/images/more_heard2.png');
          //             break;
          //         case 9:
          //             item.src = require('../../assets/images/more_heard3.png');
          //             break;
          //     }
          //     this.gameLinkList.push(item);

          // });
          this.loading = true;
          this.onLoad();
        }
      });
    },

    //根据渠道获取大区列表
    regionList() {
      this.$api.shopping.regionList({
            channel_id: this.formDate.channel_id,
          }).then((res) => {
            if (res.code == 200 && res.data.length > 0) {
              this.formDate.regions = res.data;
              if (this.formDate.regione_id) {
                var newArr = this.formDate.regions.filter((item) => {
                  return item.regione_id == this.formDate.regione_id;
                });
                this.formDate.region = newArr[0].regione_name;
                this.serverList();
              }
            }
          }).catch((err) => {
            console.log(err);
          });
    },
    //根据渠道和大区获取区服
    serverList() {
      this.formDate.servers = [];
      this.$api.shopping.serverList({
            channel_id: this.formDate.channel_id,
            regione_id: this.formDate.regione_id,
          }).then((res) => {
            if (res.code == 200) {
              for (var key in res.data) {
                this.formDate.servers.push({
                  server_name: res.data[key].server_name,
                  server_id: res.data[key].server_id,
                });
              }
              if (this.formDate.server_id) {
                var newArr = this.formDate.servers.filter((item) => {
                  return item.server_id == this.formDate.server_id;
                });
                this.formDate.server = newArr[0].server_name;
              }
            }
          }).catch((err) => {
            console.log(err);
          });
    },
    //大区和区服选择前提示
    changeClick(val) {
      if (val == 1) {
        if (this.formDate.channel_id || this.formDate.channel_id==0) {
          this.formDate.regionShowPicker = true;
        } else {
          this.$toast({
            message: "请先选择游戏，再选大区",
            duration: 1500,
          });
        }
      } else {
        if (this.formDate.region) {
          this.formDate.serverShowPicker = true;
        } else {
          this.$toast({
            message: "请先选择大区，再选区服",
            duration: 1500,
          });
        }
      }
    },
    //游戏选择确认
    onConfirm(val) {
      if (this.formDate.gameShowPicker) {
        //游戏渠道切换
        this.formDate.game = val.channelName;
        this.formDate.channel_id = val.channelId;
        this.formDate.gameShowPicker = false;
        this.formDate.region = "";
        this.formDate.server = "";
        this.formDate.regions = [];
        this.formDate.servers = [];
        // 请求获取商品分类列表
        this.getGoodsType({channel_id: this.formDate.channel_id});
        this.regionList();
        // this.gitNumTop();
      } else if (this.formDate.regionShowPicker) {
        //大区切换
        this.formDate.region = val.regione_name;
        this.formDate.regione_id = val.regione_id;
        this.formDate.regionShowPicker = false;
        this.formDate.server = "";
        this.formDate.servers = [];
        this.serverList();
      } else {
        //区服切换
        this.formDate.server = val.server_name;
        this.formDate.server_id = val.server_id;
        this.formDate.serverShowPicker = false;
      }
    },
    // 分类选择
    onClassify(val) {
      this.formDate.classifyShowPicker = false;
      this.formDate.classify = val.filter_name;
      this.formDate.onsale_type = val.filter_type;
      this.filter_type = val.filter_type;
      // var data
      // console.log(this.formDate.game,this.formDate.games)
      // this.formDate.games.forEach((list)=>{
      //     if(list.channelName==this.formDate.game){
      //         data.channel_id = list.channelId
      //     }
      // })
      let data = {
        channel_id: this.formDate.channel_id,
        filter_type: val.filter_type,
      };
      // console.log(data)
      // data.channel_id = this.formDate.channel_id
      // data.filter_type = val.filter_name
      // console.log(data)
      this.getFilterInfo(data);
      // this.getSingleOrderSwitch();
      // this.gitNumTop();
    },
    //购买大厅列表
    goodsLists() {
      let type, order;
      type = this.isActive == 4 ? 3 : this.isActive;
      order = this.isActive == 3 ? "asc" : "desc";
      this.$api.shopping.goodsLists({
            channel_id: this.formDate.channel_id,
            type: type,
            order_sort: order,
            key_word: this.key_word,
            price_min: this.formDate.price_min,
            price_max: this.formDate.price_max,
            lv_min: this.formDate.lv_min,
            lv_max: this.formDate.lv_max,
            zs_lv_min: this.formDate.zs_lv_min,
            zs_lv_max: this.formDate.zs_lv_max,
            regione_id: this.formDate.regione_id,
            server_id: this.formDate.server_id,
            big_demon: this.formDate.big_demon,
            little_demon: this.formDate.little_demon,
            page: this.page,
          }).then((res) => {
            if (res.code == 200) {
              if (res.data.data.length > 0) {
                res.data.data.forEach((item) => {
                  this.list.push(item);
                });
                // console.log(this.list);
                //加载状态结束
                this.loading = false;
                //数据全部加载完成
                if (this.page * 8 >= this.total) {
                  this.finished = true;
                }
                this.page++;
                //关掉错误提示
                this.error = false;
                this.list.forEach((item) => {
                  //获取上架中的状态样式
                  item.isActive = item.status == 2 ? false : true;
                  if (this.formDate.onsale_type !== 1) {
                    item.imgSrc = require("../../assets/images/itemicon/" +
                        item.channel_id +
                        "/" +
                        item.icon_id +
                        ".png");
                    //    return false
                  } else {
                    if (item.sex == 200) {
                      switch (item.job) {
                        case "战士":
                          item.imgSrc = require("../../assets/images/photos/zs-nan@2x.png");
                          break;
                        case "法师":
                          item.imgSrc = require("../../assets/images/photos/fa-nan@2x.png");
                          break;
                        default:
                          item.imgSrc = require("../../assets/images/photos/ds-nan@2x.png");
                      }
                    } else {
                      switch (item.job) {
                        case "战士":
                          item.imgSrc = require("../../assets/images/photos/zs-nv@2x.png");
                          break;
                        case "法师":
                          item.imgSrc = require("../../assets/images/photos/fa-nv@2x.png");
                          break;
                        default:
                          item.imgSrc = require("../../assets/images/photos/ds-nv@2x.png");
                      }
                    }
                  }
                  // return false
                  //获取头像
                });
              } else {
                //加载状态结束
                this.loading = false;
                //数据全部加载完成
                this.finished = true;
              }
            } else {
              //加载状态结束
              this.loading = false;
              //错误提示
              this.error = true;
            }
          }).catch((err) => {
            console.log(err);
          });
    },
    //重置
    reSet() {
      let data = {
        channel_id: this.formDate.channel_id,
        filter_type: this.formDate.onsale_type,
      };
      this.getFilterInfo(data);
    },
    //搜索
    onSearch() {
      // console.log(type);
      // this.getSingleOrderSwitch();
      // this.gitNumTop();
      this.show = false;
      // this.getInitData(2);//获取筛选默认项
      this.isSearch = true;
      this.finished = false;
      this.error = false;
      this.list = [];
      this.page = 1;
      this.loading = true;
      // if (type == 1 && this.key_word == '') {
      //    this.formDate.server_id='';
      //    this.formDate.regione_id=''
      // };
      this.onLoad();
      // if (this.filter_type == 2) {
      //   this.isAddPayRecommend = false;
      // }
      // if (this.filter_type == 2) {
      //   this.isAddPayRecommend = true;
      // } else {
      //   this.isAddPayRecommend = false;
      // }

    },
    //列表加载
    onLoad() {
      //console.log(this.refreshing);
      this.regionList1();
      // this.gitNumTop();
      // this.list = [];
      if (this.refreshing) {
        // this.list = [];
        this.refreshing = false;
      }
      // 异步更新数据
      let type,
          order,
          job,
          data = {};
      type = this.isActive == 4 ? 3 : this.isActive;
      order = this.isActive == 3 ? "asc" : "desc";
      // job = this.formDate.professionCheck.indexOf('all') > -1 ? '' : (this.formDate.professionCheck.length > 2 ? '' : this.formDate.professionCheck.join());
      if (this.boundData.job) {
        job = this.boundData.job.join();
      }

      // console.log(111,this.isSearch)
      if (this.isSearch) {
        data = {
          channel_id: this.formDate.channel_id,
          type: type,
          order_sort: order,
          key_word: this.key_word,
          // price_min: this.formDate.price_min,
          // price_max: this.formDate.price_max,
          // lv_min: this.formDate.lv_min,
          // lv_max: this.formDate.lv_max,
          // zs_lv_min: this.formDate.zs_lv_min,
          // zs_lv_max: this.formDate.zs_lv_max,
          // regione_id: this.formDate.regione_id,
          // server_id: this.formDate.server_id,
          // big_demon: this.formDate.big_demon,
          // little_demon: this.formDate.little_demon,
          // page: this.page,
          // job: job
        };
        data = Object.assign(data, this.boundData);
        if (this.boundData.job) {
          data.job = this.boundData.job.join();
        }
        data.page = this.page;
        //搜索时关键词为空则
        data.regione_id = this.formDate.regione_id;
        data.server_id = this.formDate.server_id;
        data.onsale_type = this.formDate.onsale_type;
        // console.log(data.onsale_type);
          // if (data.key_word=='') {
            // data.server_id='';
            // data.regione_id=''
          // };
        // if (this.formDate.channel_id==18) {
        //   if (data.onsale_type == 2) {
        //     this.isAddPayRecommend = true;
        //     } else {
        //     this.isAddPayRecommend = false;
        //     }
        //     }else{
        //       this.isAddPayRecommend = false;
        //     };
        if (data.equipment_part) {
          data.equipment_part = this.boundData.equipment_part.join();
        }
        if (data.black_type) {
          data.black_type = this.boundData.black_type.join();
        }
        this.istype = this.formDate.onsale_type;
        // console.log(data)
      } else {
        data = {
          channel_id: this.formDate.channel_id,
          type: type,
          order_sort: order,
          page: this.page,
          onsale_type:this.formDate.onsale_type
        };
      }

      // return false
      this.$api.shopping.goodsLists(data).then((res) => {
            if (res.code == 200) {
              this.key_word= '';
              // console.log(res);
              if (res.data.data.length > 0) {
                res.data.data.forEach((item) => {
                  this.list.push(item);
                });
                // console.log(this.list[0]);
                //判断列表渲染是不是仙玉
                // if(this.list[0].onsale_type==2){
                //   this.isAddPayRecommend = true;
                //   } else {
                //   this.isAddPayRecommend = false;
                // }
                this.total = res.data.total;
                //加载状态结束
                this.loading = false;
                //数据全部加载完成
                if (this.page * 8 >= this.total) {
                  this.finished = true;
                }
                this.page++;
                //关掉错误提示
                this.error = false;
                this.list.forEach((item) => {
                  //获取上架中的状态样式
                  item.isActive = item.status == 2 ? false : true;
                  //获取头像
                  item.isActive = item.status == 2 ? false : true;
                  if (item.onsale_type != 1) {
                    var imgSrc = "";
                    try {
                      imgSrc = require("../../assets/images/itemicon/" +
                          item.channel_id +
                          "/" +
                          item.icon_id +
                          ".png");
                    } catch (e) {
                      imgSrc = require("../../assets/images/question.png");
                    }

                    this.$set(item, "imgSrc", imgSrc);

                    return false;
                  }
                  if (item.sex == 200) {
                    switch (item.job) {
                      case "战士":
                        item.imgSrc = require("../../assets/images/photos/zs-nan@2x.png");
                        break;
                      case "法师":
                        item.imgSrc = require("../../assets/images/photos/fa-nan@2x.png");
                        break;
                      default:
                        item.imgSrc = require("../../assets/images/photos/ds-nan@2x.png");
                    }
                  } else {
                    switch (item.job) {
                      case "战士":
                        item.imgSrc = require("../../assets/images/photos/zs-nv@2x.png");
                        break;
                      case "法师":
                        item.imgSrc = require("../../assets/images/photos/fa-nv@2x.png");
                        break;
                      default:
                        item.imgSrc = require("../../assets/images/photos/ds-nv@2x.png");
                    }
                  }
                });
              } else {
                //加载状态结束
                this.loading = false;
                //数据全部加载完成
                this.finished = true;
              }
            } else {
              //加载状态结束
              this.loading = false;
              //错误提示
              this.error = true;
            }
          }).catch((err) => {
            // console.log(111, err);
            // this.list.forEach(item => {
            //     if (!item.imgSrc) {
            //         let imgSrc = require('../../assets/images/question.png');
            //         this.$set(item, 'imgSrc', imgSrc);
            //     }
            // });
          });
    },
    //tab切换
    sortMeans(type) {
      if (this.isActive == 3 && type == 3) {
        this.isActive = 4;
      } else {
        this.isActive = type;
      }
      this.finished = false;
      this.error = false;
      this.list = [];
      this.page = 1;
      this.loading = true;
      this.onLoad();
    },
    //下拉刷新
    onRefresh() {
      //公示期
      // if (this.isActive==2) {
        this.list = [];
      // }
      //清空列表数据
      this.finished = false;
      this.error = false;
      //重新加载数据
      //将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.page = 1;
      this.onLoad();
    },
    //获取筛选默认项
    getInitData(type) {
      let param = {},
          job;
      job =this.formDate.professionCheck.indexOf("all") > -1 ? "" : this.formDate.professionCheck.length > 2 ? "" : this.formDate.professionCheck.join();
      param = (type == 1) ? {type: type} : {
                type: type,
                channel_id: this.formDate.channel_id,
                price_min: this.formDate.price_min,
                price_max: this.formDate.price_max,
                lv_min: this.formDate.lv_min,
                lv_max: this.formDate.lv_max,
                zs_lv_min: this.formDate.zs_lv_min,
                zs_lv_max: this.formDate.zs_lv_max,
                regione_id: this.formDate.regione_id,
                server_id: this.formDate.server_id,
                big_demon: this.formDate.big_demon,
                little_demon: this.formDate.little_demon,
                job: job,
              };
      this.$api.shopping.getInitData(param).then((res) => {
            if (res.code == 200) {
              let data = res.data[0];
              if (type == 1) {
                this.formDate.channel_id = data.channel_id? data.channel_id: this.formDate.games[0].channelId;
                this.formDate.price_min = data.price_min;
                this.formDate.price_max = data.price_max;
                this.formDate.lv_min = data.lv_min;
                this.formDate.lv_max = data.lv_max;
                this.formDate.zs_lv_min = data.zs_lv_min;
                this.formDate.zs_lv_max = data.zs_lv_max;
                this.formDate.big_demon = data.big_demon;
                this.formDate.little_demon = data.little_demon;
                this.formDate.regione_id = data.regione_id;
                this.formDate.server_id = data.server_id;
                this.formDate.professionCheck =
                    data.job != "" ? data.job.split(",") : ["all"];
                //this.gameChannelList();
              }
            }
          }).catch((err) => {
            console.log(err);
          });
    },
    //筛选点击
    screenClick() {
      this.show = true;
      // this.getInitData(1); //获取筛选默认项
    },
    //公告请求
    getNotice() {
      if(sessionStorage.getItem('type')=='24'){
        return false
      }
      //获取本地保存的公告内容
      let noticehtml, noticecheck, timestamp, totaltime, nowtime;
      noticehtml = localStorage.getItem("noticeHtml");
      noticecheck = localStorage.getItem("noticeCheck");
      timestamp = localStorage.getItem("timestamp");
      this.$api.shopping.getNotice({
            channel_id: this.formDate.channel_id,
          }).then((res) => {
            if (res.code == 200) {
              if (JSON.parse(noticecheck)) {
                if (res.data.noticeContent) {
                  if (res.data.noticeContent != noticehtml) {
                    this.dialogShow = true;
                  } else {
                    this.dialogShow = false;
                  }
                } else {
                  this.dialogShow = false;
                }
              } else {
                if (timestamp != "null") {
                  totaltime = 6 * 60 * 60 * 1000;
                  nowtime = new Date().getTime();
                  if (nowtime - JSON.parse(timestamp) > totaltime) {
                    this.dialogShow = true;
                  } else {
                    this.dialogShow = false;
                  }
                } else {
                  this.dialogShow = true;
                }
              }
              this.noticeHtml = res.data.noticeContent;
              this.noticeType = res.data.type;
              if (res.data.type == 2) {
                this.noticeSrc =
                    process.env.VUE_APP_URL + "/" + res.data.noticeContent;
                //console.log(this.noticeSrc);
              }
              localStorage.setItem("noticeHtml", res.data.noticeContent);
            }
          }).catch((err) => {
            console.log(err);
          });
    },
    onLoadImg: function (imgSrc) {
      return console.log("onload", imgSrc);
    },
    onErrorImg: function (imgSrc, index) {
      // console.log("onErrorImg", imgSrc ,index)
      imgSrc = require("../../assets/images/question.png");
      this.list[index].imgSrc = require("../../assets/images/question.png");
      // return false
      // console.log("onErrorImg", imgSrc)
      // return false
      // return 'this.οnerrοr=null;this.src='+'"'+imgSrc+'";';
    },
    //公告确认
    confirmClick() {
      this.dialogShow = false;
      let timestamp;
      if (!this.dialogChecked) {
        timestamp = new Date().getTime();
        localStorage.setItem("timestamp", timestamp);
      }
      localStorage.setItem("noticeCheck", this.dialogChecked);
    },
    //只能输入正整数
    getNumber(obj, key) {
      obj[key] = String(obj[key]).replace(/[^\d]/g, "");
    },
    //监听输入框只能输入正整数
    handlerInp() {
      // this.getNumber(this.formDate, 'lv_min');
      // this.getNumber(this.formDate, 'lv_max');
      // this.getNumber(this.formDate, 'zs_lv_min');
      // this.getNumber(this.formDate, 'zs_lv_max');
      // this.getNumber(this.formDate, 'big_demon');
      // this.getNumber(this.formDate, 'little_demon');
    },
    //点击遮罩层
    overlayClick() {
      if (this.setKeyboard) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    // //筛选关闭事件
    // downFilterHandle(){
    //    this.formDate.region='';
    //    this.formDate.server=''
    // },
    //关闭公告
    noticeClose() {
      this.dialogShow = false;
      let timestamp;
      if (!this.dialogChecked) {
        timestamp = new Date().getTime();
        localStorage.setItem("timestamp", timestamp);
      }
      localStorage.setItem("noticeCheck", this.dialogChecked);
    },
    //获取货币交易规则
    getRule() {
      this.$api.sale
        .getRule({ channel_id: this.formDate.channel_id })
        .then((res) => {
          if (res.code == 200) {
            this.tradingRules = res.data.currencyDesc==''?'':this.replaceNewlinesWithBr(res.data.currencyDesc);
          }else{
            this.tradingRules = '';
          }
        })
    },
    replaceNewlinesWithBr(text) {
      // 使用正则表达式将 \r\n 或 \r 或 \n 替换为 <br>
      return text.replace(/[\r\n]+/g, '<br>');
    },
    //货币列表轮询
    listPolling(){
      this.listPollingTimer = setInterval(() => {
        // setTimeout自带清除定时器，结合使用解决setInterval每重复执行1次都会导致定时器叠加，导致网页卡死问题--性能优化
         setTimeout(() => {
          //请求货币列表
          this.getScaleGoods()
          }, 0)
        }, 10000)
    },
    //清空货币列表轮询
    clearListPollingTimer(){
      clearInterval(this.listPollingTimer);        
      this.listPollingTimer = null;
    }
  },
  watch: {
     //监听类型
     'formDate.onsale_type'(newV, oldV){
      //  console.log(newV);
       this.filter_type_isActive=newV
       if(newV=='2' || newV=='4'){
         this.isAddPayRecommend = true;
       }else{
         this.isAddPayRecommend = false
       }
     },
    // 未选角色提醒
    'currencyForm.role'(newV, oldV){
      if (newV) {
        this.closeNullWarn=false
      }else{
        this.closeNullWarn=true
      }
    },
    //未选区服不显示区服状态
    'currencyForm.server'(newV, oldV){
      // console.log(newV);
      // if (newV) {
      //   this.getMergeServer()
      //   this.isShowServeStatus=true
      // }else{
      //   this.isShowServeStatus=false
      // }
    },
    //选择游戏tab监听
    'isSelectGameShow'(newV, oldV){
      localStorage.setItem('isSelectGameShow',newV)
      // console.log(newV);
    },
    //监听用户之前打开了哪个游戏
    // 'formDate.channel_id'(newV, oldV){
    //   console.log(newV);
    //   // localStorage.setItem('isSelectGameShow',newV)
    // },
    // this.currencyForm.role
    openOrBackName: {
      deep: true,
      handler(newValue, oldValue) {
        this.openOrBackName = newValue;
        // console.log('watch',this.openOrBackName);
      },
    },
  },
  beforeDestroy() {
    this.debounce = null; //清除闭包
    //清空货币列表轮询
    this.clearListPollingTimer()
    //清除有金额订单锁定定时器
    clearInterval(this.isDownTime);
  },
};
</script>
<style scoped>
.home_box{
  height:100%;
  width: 100%;
  background-color: #F4F3F3 ;
}
.switchover_game{
  font-size: 1rem;
  color: #fff;
  position: absolute;
  top: 9px;
  z-index: 3;
  right: 5px;
}
.select_game_box{
  height: 2.5rem;
  width: 100%;
  color: #fff;
  font-size: 1.1rem;
  line-height: 2.5rem;
  background-image: linear-gradient(#ff664c, #f84238);
}
.select_game_back{
 float:left;
 line-height: 2.5rem;
 font-size: 1.2rem;
 padding-left:10px
}
.official_box{
  height: 25rem;
  width: 100%;
  font-size: 1rem;
  color: #818181;
  margin-top: 3rem;
  /* background: rgb(236, 235, 235); */
  /* position:fixed; */
  left: 0;
  bottom: 0;
}
.moreGame{
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.moreGame li{
    width: 5.125rem;
    font-size: 0.85rem;
}
.moreGame li .moreGame_bg{
    width: 5.125rem;
    height: 5.125rem;
    background-image: url('../../assets/images/more_game_bg.png');
    background-size: 100% 100%;
    position: relative;
}
.moreGame li .moreGame_bg img{
    width: 3.75rem;
    height: 3.75rem;
    position: absolute;
    top: 14%;
    left: 14%;
    border-radius: 50%;
}
.moreGame li .moreGame_name{
    width: 3.4375rem;
    height: 2.71875rem;
    margin: 0 auto;
    position: relative;
}
.moreGame li .moreGame_name span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}
.moreGame .van-empty{
    margin: 0 auto;
}
.more_div{
  width: 100%;
  background-color: #fff;
}
.more {
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: space-around;
}

.more li {
  /* width: 16%; */
  margin-top: 0.8125rem;
  margin-bottom: 0.1875rem;
}

.more li img {
  width: 4.5rem;
  /* border-radius: 20%; */
  display: block;
  margin:10px 0;
  border-radius: 50%;
}

.more li span {
  color: #969696;
  font: 0.81875rem/1.28125rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
}
.goods_type_box{
  background-color: #fff;
}
.goods_type_bg{
  color: #fff !important;
  background: url(../../assets/images/box_update/select_item@2x.png) center no-repeat;
	background-size:99% 99%;
  border: none !important;
}
.goods_type_bg span{
  background: #ff653c !important;
  /* height: 3.3rem !important; */
  border: none !important;
  margin: 0 auto !important;
}
.goods_type_box ul{
   /* margin-left: 10px; */
   text-align: left;
   display: flex;
   justify-content: flex-start;
   padding: 2px 0 0 10px;
}
.goods_type_box ul li{
  height: 4.5rem;
  width: 4rem;
}
.goods_type_box ul li div{
  width: 3.4rem;
  height: 3.8rem;
  text-align: center;
  line-height: 3.4rem;
  /* border-radius: 50%; */
  font-size: 0.85rem;
  color: #f83f2a;
  background-color: #fff;
  /* border: 1px #f83f2a solid; */
  margin: 4px;
}
.goods_type_box ul li div span{
  display: block;
  height: 3.2rem;
  width: 3.2rem;
  border: 1px #f83f2a solid;
  /* background: #f83f2a; */
  border-radius: 50%;
  margin: 0px auto;
}
.currency_box{
  width: 100%;
  background-color: #F4F3F3 ;
  padding-bottom: 40px;
}
.index_input_box{
  width: 100%;
  height: 165px;
  background: #fff;
  border-top: 2.5px dotted rgb(199, 196, 196);
}
.index_input_box .role_span{
  float: right;
  padding-top: 0.4rem;
  margin-right: 20px;
  color: #7e7c7c;
  font-size: 0.85rem;
  display: flex;
}
.role_span .van-icon-question::before {
  font-size: 1.2rem;
  margin-right: 0.3rem;
}
.warn_box_null{
  height: 335px;
  width: 100%;
  color: #808080;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.warn_box_null img{
  width: 6rem;
  margin-bottom: 0.2rem;
}
.xian_yu_box{
  width: 100%;
  padding: 5px 0 80px 0;
  background: #fff;
}
.xian_yu_box_p{
  width: 100%;
  font-size: 0.8rem;
  color: red;
  padding-top: 3px;
}
.xian_yu_box_p span{
  background-color: #f75236;
  margin-left: 0.5rem;
  padding: 0.2rem;
  color: #fff;
  border-radius: 0.2rem;
}
.xian_yu_item_box img{
  width: 3.4rem;
  height: 3.4rem;
  background-color: #c3bcbc;
  border-radius: 0.3rem;
}
.xian_yu_item_box li{
  display: flex;
  border: 1px #e5e5e5 solid;
  margin: 15px 12px;
  padding: 8px;
  border-radius: 5px;
}
.xian_yu_item_box li:active{
  background: #f28c71;
  color: #fff;
  border: 1px #f28c71 solid;
}
.xian_yu_item{
  margin-left: 10%;
}
.show_transaction_role_css /deep/ .van-dialog__header{
   padding-top: 15px;
}
.show_transaction_role_box{
  width: 100%;
  min-height: 150px;
  border-top: 2.5px dotted rgb(199, 196, 196);
  padding: 8px 21px;
  text-align: left;
  font-size: 0.9rem;
  color: #747171;
}
.payRecommendBox {
  width: 100%;
}
.payRecommendBox .payRecommendBox2 {
  width: 70%;
  margin:0.2rem 0 0.5rem 2.5rem ;
  height: 2.4rem;
  background: #ddd;
  line-height: 2.4rem;
  border-radius: 4px;
  color: #f75236;
}
.payRecommendBox2 p{
  width: 90%;
  margin-left: 1rem;
}
.payRecommendBox2 p span{
  font-weight: 600;
}
.show_pay_scope{
  padding: 10px;
  text-align: left;
  max-height: 120px;
  overflow-y: auto;
  font-size: 0.95rem;
}
.show_pay_scope_css /deep/ .van-dialog__header {
    border-bottom: 1px rgb(211, 209, 209) dashed;
}


#index_a{
  background: #f4f3f3;
}
.van-search__action {
  color: #ff4e00;
  padding-right: 20px;
}

.van-search__content {
  border-radius: 20px;
}


.sort {
  position: relative;
}

.sort .top {
  position: absolute;
  right: 23%;
  top: 25%;
  font-size: 11px;
}

.sort .bottom {
  position: absolute;
  right: 23%;
  bottom: 23%;
  font-size: 11px;
}

.action {
  color: #ff4e00;
}

.action_top span {
  color: #ff4e00;
}

.action_bottom span {
  color: #ff4e00;
}

.action_top .top {
  color: #ff4e00;
}

.action_bottom .bottom {
  color: #ff4e00;
}

.filterColumn .van-col {
  line-height: 2.34375rem;
  background: #fbfbfb;
}

.popup_top .van-col {
  line-height: 24px;
  /* padding: .5625rem; */
}

.popup_top .profession button {
  height: 1.5625rem;
  color: #313131;
}

.isProfession {
  background: linear-gradient(to right, rgb(74, 154, 247), rgb(1, 101, 218));
  color: #fff !important;
}

.popup_top .profession .van-button--large {
  height: 50px;
  border-color: #6d6d6d;
}

/* 公告 */
.notice {
  z-index: 3;
}

.notice .wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.notice .block_w {
  width: 90%;
  /* height: 24.375rem; */
  background-color: #fff;
  border-radius: 0.5625rem;
  padding: 0.15625rem 0.84375rem;
  position: relative;
}

.notice .block_w .van-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.notice h3 {
  font: 0.9375rem/2.4375rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  margin: 0;
  border-bottom: 1px solid #b3b3b3;
}

.notice .wrap_c {
  overflow-y: auto;
  /* max-height: 16rem; */
  /* height: 16rem; */
  padding: 2% 0;
  text-align: left;
}

.notice button {
  height: 40px;
  font-size: 1rem;
}

.notice .van-checkbox {
  justify-content: center;
  margin: 3% 0;
}

.notice .van-checkbox__label {
  font-size: 14px;
}

.annotation {
  color: #ff4e00;
  text-align: left;
}

.login_btns {
  padding-bottom: 3%;
}

/*添加购买仙玉推荐 */
.addPayRecommend {
  height: 4.21rem;
  width: 100%;
  background-color: #f4f3f3;
  padding: 0.2083rem 0.2708rem 0.2083rem 0.2708rem;
}

.addPayRecommend .payRecommend {
  height: 3.85rem;
  width: calc(100% - 13px);
  background: #fff;
  /* border: 1.5px dashed rgb(199, 196, 196); */
  padding: 0.125rem 0 0.1458rem 0.25rem;
  display: flex;
  justify-content: space-between;
  margin-left: 1.5%;
}

.payRecommend img {
  display: block;
  height: 3.3958rem;
  margin-top: 0.1042rem;
}

.payRecommend_span {
  width: 59%;
  height: 0.8333rem;
  text-align: left;
  padding-top: 8px;
  color: #ff4e00;
}

.payRecommend_btn .van-button {
  width: 66px;
  height: 26px;
  margin: 17px 10px 0 0;
  background: #ff4e00;
  border: none;
}
.payRecommend_item_span{
  font-size: 0.9rem;
  font-weight: 600;
}
.payRecommend_select {
  background: #f4f3f3;
  width: 100%;
  /* height: 700px; */
  padding: 0 0 0.2083rem 1.4%;
}
.payRecommend_select1 {
  background: #f4f3f3;
  width: 100%;
  /* height: 700px; */
  padding: 0 0 0.2083rem 1.4%;
}

.payRecommend_item {
  width: 16%;
  height: 1.9rem;
}
.payRecommend_item1 {
  width: 17%;
  height: 2.9rem;
}
.payRecommend_select_content {
  width: 98%;
  background: #fff;
  border-top: 2.5px dotted rgb(199, 196, 196);
  padding: 0.625rem 0 0.9375rem 0;
  color: #808080;
  border-bottom-left-radius: 0.4167rem;
  border-bottom-right-radius: 0.4167rem;
}
.payRecommend_select_content1 {
  height:400px;
  padding: 1.3rem 0 0.9375rem 0;
  color: #5e5c5c;
  border-bottom-left-radius: 0.4167rem;
  border-bottom-right-radius: 0.4167rem;
}

.payRecommend_item_img {
  display: block;
  width: 1.575rem;
  height: 1.93rem;
  margin-left: -25px;
  margin-right: 3px;
}
.payRecommend_item_img1 {
  display: block;
  width: 2.575rem;
  height: 2.93rem;
  margin-left: -25px;
  margin-right: 3px;
}

.payRecommendSpan {
  position: relative;
  top: -2.46rem;
  left: 5.37rem;
}



.go_pay_span_color {
  color: #ff4e00;
}

.go_pay_content {
  width: 100%;
  height: 203px;
  padding-top: 0.8333rem;
}
#go_pay_content{
  height: 183px;
}

.go_pay_content_num {
  margin-top: 2px;
  width: 90%;
  margin-left: 5%;
  padding: 0.8333rem 0 0 3.0833rem;
  border-top: 2.5px dotted rgb(169 163 163);
  text-align: left;
}

.go_pay_content .bad_scalp {
  font-size: 0.808rem;
  width: 94%;
  display: block;
  /* height: 30px; */
  padding: 8px 10px 2px 10px;
  text-align: center;
  color: #e02d43;
}
  .dialogGoPay1
  span {
  font-size: 15px;
  margin: 9px;
}
/* 支付功能 */
.choosePay .close {
  height: 0.9792rem;
  position: relative;
  margin-top: 0.7083rem;
}
.choosePay .close i {
  position: absolute;
  background: url("../../assets/images/close.png") no-repeat;
  background-size: 100% 100%;
  width: 1rem;
  height: 0.9792rem;
  top: 0;
  right: 0.75rem;
}
.choosePay .title {
  font-size: 1.0208rem;
  color: #313131;
  padding-bottom: 1.0208rem;
  border-bottom: 0.0417rem solid #b3b3b3;
}
.choosePay_list {
  padding: 1.7708rem 0 0.4167rem;
}
.choosePay_list li {
  display: flex;
  justify-content: space-between;
  width: 88%;
  margin: 0 auto 1.4167rem;
  height: 4.1042rem;
  background: #efefef;
  border-radius: 0.5rem;
  align-items: center;
  border: 0.0417rem solid #d8d8d8;
}
.choosePay_list li img {
  width: 1.9375rem;
  height: 1.9375rem;
  margin-left: 1.1042rem;
}
.choosePay_list li > div {
  width: 86%;
  text-align: center;
  font-size: 1.0833rem;
}
.blue {
  color: #19a0e5;
}
.choosePay_list .yue {
  font-size: 0.8542rem;
  color: #808080;
}

.password_input .close {
  text-align: right;
  font: 0.875rem/1.3125rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  padding-top: 0.3125rem;
}
.password_input .close i {
  margin-right: 0.3375rem;
}
.password_border {
  border-radius: 0.84375rem;
  background: #efefef;
}
.password_input .password_text {
  font: 0.9375rem /1.875rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  color: #313131;
  border-bottom: 1px solid #b3b3b3;
}
.password_input .password_sum {
  font: 900 1.34375rem/1.84375rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  margin-top: 0.9375rem;
}
.password_input .password_deduction {
  font: 0.71875rem/1.21875rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  padding-bottom: 0.71875rem;
  border-bottom: 1px dashed #b3b3b3;
  margin-bottom: 0.96875rem;
}
.van-password-input {
  padding-top: 3.0625rem;
}
.affirm_pay {
  width: 12.5rem;
  margin: 1.8125rem auto 1.9167rem;
}
.van-password-input__security li {
  background: #f0f0f0;
  margin-right: 0.1875rem;
}
.password_input .password_forget {
  font: 0.98125rem/2.71875rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  color: #227eeb;
  text-align: right;
  padding-right: 1rem;
}
.van-number-keyboard {
  z-index: 200004;
}
.index_input_box_height{
  height: 205px;
}
</style>
<style>
.payRecommendBox .payRecommendBox1 .van-field__control{
   /* background: #ccc; */
   /* text-align: right; */
   padding: 3px 3px 3px 5px;
   border: 1px #ccc solid;
   border-radius: 3px;
   width: 100px;
}
.payRecommendBox1 .van-field__label {
    line-height: 2rem;
}

.choosePay .van-hairline--surround::after {
  border: 0;
}
/*添加购买仙玉推荐 */
.payRecommend_select_content .van-grid-item__content {
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  width: 5.2708rem !important;
  height: 1.8333rem !important;
}
.payRecommend_select_content1 .van-grid-item__content {
    border: 1px #e5e2e2 solid ;
    border-radius: 6px;
}
.payRecommend_select_content .van-grid-item__content:active {
  background: #fbd8c5;
  color: #ff4e00;
  border: 1px solid #fe6847;
}
.payRecommend_select_content1 .van-grid-item__content:active {
  background: #fbd8c5;
  color: #ff4e00;
  border: 1px solid #fe6847;
}


.dialogInput .van-dialog__header {
  color: #fe6847;
  font-size: 0.9rem;
}
.dialogInput .van-field__label{
  text-align: right;
}

/*  */
.popup_top .van-field__label {
  width: auto;
}

.progroup .van-col .van-checkbox__icon {
  display: none;
}

.progroup .van-col .van-checkbox__label {
  /* padding: 0 15px; */
  height: 1.5625rem;
  font-size: 13px;
  line-height: 1.6625rem;
  /* background: linear-gradient(to right, rgb(74, 154, 247), rgb(1, 101, 218)); */
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #ebedf0;
  margin-bottom: 0.5rem;
  width: 100%;
}

.classification .van-col .van-checkbox__label {
  background: #efefef;
}

.classification .van-col .van-checkbox__label {
  padding: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.progroup .checked .van-checkbox__label {
  background: linear-gradient(to right, rgb(255, 112, 77), rgb(247, 55, 39));
  color: #fff;
}

.classification .checked .van-checkbox__label {
  background: #ff4e00;
  border-color: #ff4e00;
}
</style>
